import React from 'react';
import { RiApps2Line, RiChat3Line } from 'react-icons/ri';
import { BsBarChart } from 'react-icons/bs';
import { MdOutlineOndemandVideo, MdOutlinePrivacyTip } from 'react-icons/md';
import { BiGame, BiMessageDetail } from 'react-icons/bi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { HiOutlineGift, HiOutlineCog } from 'react-icons/hi';
import { TbForms } from 'react-icons/tb';
import { TiDocumentText } from 'react-icons/ti';
import { IoNewspaperOutline } from 'react-icons/io5';

import HomePage from '../pages/Home';
import ProfilePage from '../pages/Profile';
import CoursesPage from '../pages/Courses';
import CoursesDetailPage from '../pages/CoursesDetail';
import LessonPage from '../pages/Lesson';
import GamesPage from '../pages/Games';
import GamesDetailPage from '../pages/GamesDetail';
import RankingPage from '../pages/Ranking';
import RewardsPage from '../pages/Rewards';
import MessagesPage from '../pages/Messages';
import SettingsPage from '../pages/Settings';
import AuthPage from '../pages/Auth';
import ContactPage from '../pages/Contact';
import FaqPage from '../pages/Faq';
import PrivacyPage from '../pages/Privacy';
import TermsPage from '../pages/Terms';
import NewsPage from '../pages/News';

export interface pathsInterface {
  id: string;
  title: string;
  pathname: string;
  icon: React.ReactNode;
  onlyInRouter: boolean;
  private: boolean;
  component: React.ReactNode;
  children?: this[];
}

const PATHS: pathsInterface[] = [
  {
    id: 'auth',
    title: 'Login',
    pathname: '/login',
    icon: undefined,
    onlyInRouter: true,
    private: false,
    component: <AuthPage />,
  },
  {
    id: 'home',
    title: 'Home',
    pathname: '/',
    icon: <RiApps2Line />,
    onlyInRouter: false,
    private: true,
    component: <HomePage />,
  },
  {
    id: 'profile',
    title: 'Seu perfil',
    pathname: '/seu-perfil',
    icon: <BsBarChart />,
    onlyInRouter: false,
    private: true,
    component: <ProfilePage />,
  },
  {
    id: 'courses',
    title: 'Cursos',
    pathname: '/cursos',
    icon: <MdOutlineOndemandVideo />,
    onlyInRouter: false,
    private: true,
    component: <CoursesPage />,
    children: [
      {
        id: 'courses-detail',
        title: 'Cursos',
        pathname: ':courseID',
        icon: undefined,
        onlyInRouter: true,
        private: true,
        component: <CoursesDetailPage />,
        children: [
          {
            id: 'courses-module-detail',
            title: 'Cursos',
            pathname: ':folderID',
            icon: undefined,
            onlyInRouter: true,
            private: true,
            component: <LessonPage />,
            children: [
              {
                id: 'courses-view-content',
                title: 'Cursos',
                pathname: ':contentID',
                icon: undefined,
                onlyInRouter: true,
                private: true,
                component: <LessonPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'games',
    title: 'Games',
    pathname: '/games',
    icon: <BiGame />,
    onlyInRouter: false,
    private: true,
    component: <GamesPage />,
    children: [
      {
        id: 'games-detail',
        title: 'Games',
        pathname: ':gameID',
        icon: undefined,
        onlyInRouter: true,
        private: true,
        component: <GamesDetailPage />,
      },
    ],
  },
  {
    id: 'ranking',
    title: 'Ranking',
    pathname: '/ranking',
    icon: <AiOutlineTrophy />,
    onlyInRouter: false,
    private: true,
    component: <RankingPage />,
  },
  {
    id: 'rewards',
    title: 'Recompensas',
    pathname: '/recompensas',
    icon: <HiOutlineGift />,
    onlyInRouter: false,
    private: true,
    component: <RewardsPage />,
    children: [
      {
        id: 'rewards-detail',
        title: 'Recompensas',
        pathname: ':id',
        icon: <HiOutlineGift />,
        onlyInRouter: true,
        private: true,
        component: <RewardsPage />,
      },
    ],
  },
  {
    id: 'news',
    title: 'Notícias',
    pathname: '/noticias',
    icon: <IoNewspaperOutline />,
    onlyInRouter: false,
    private: true,
    component: <NewsPage />,
  },
  {
    id: 'messages',
    title: 'Mensagens',
    pathname: '/mensagens',
    icon: <RiChat3Line />,
    onlyInRouter: false,
    private: true,
    component: <MessagesPage />,
  },
  {
    id: 'settings',
    title: 'Configurações',
    pathname: '/configuracoes',
    icon: <HiOutlineCog />,
    onlyInRouter: false,
    private: true,
    component: <SettingsPage />,
    children: [
      {
        id: 'faq',
        title: 'FAQ',
        pathname: 'faq',
        icon: <BiMessageDetail />,
        onlyInRouter: true,
        private: true,
        component: <FaqPage />,
      },
      {
        id: 'terms',
        title: 'Termos de uso',
        pathname: 'termos-de-uso',
        icon: <TiDocumentText />,
        onlyInRouter: true,
        private: true,
        component: <TermsPage />,
      },
      {
        id: 'privacy',
        title: 'Política de privacidade',
        pathname: 'politica-de-privacidade',
        icon: <MdOutlinePrivacyTip />,
        onlyInRouter: true,
        private: true,
        component: <PrivacyPage />,
      },
      {
        id: 'contact',
        title: 'Contato',
        pathname: 'contato',
        icon: <TbForms />,
        onlyInRouter: true,
        private: true,
        component: <ContactPage />,
      },
    ],
  },
];

export default PATHS;
