import { useState, useEffect, useMemo, useCallback } from 'react';

import { useAuth, useBasic } from 'contexts';
import { MessagesFullInterface } from 'types';
import { Placeholder } from 'components';
import { delNotification, getAllMessages, putReadMessages } from 'services';
import ItemNotification from './ItemNotification';

import './style.scss';

export default function MessagesPage() {
  const { user } = useAuth();
  const { messagesIDs, loadingMessagesIDs } = useBasic();

  const [active, setActive] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<MessagesFullInterface | undefined | null>();

  const unreadMessages = useMemo(() => {
    let notificationsPublic = 0,
      notificationsPrivate = 0;

    if (messagesIDs && messages) {
      notificationsPublic = messagesIDs?.public?.filter(x => !x.read).length || 0;
      notificationsPrivate = messagesIDs?.private?.filter(x => !x.read).length || 0;
    }

    return { notificationsPublic, notificationsPrivate };
  }, [messagesIDs, messages]);

  const getMessages = useCallback(async () => {
    try {
      const _messages = await getAllMessages(user?._id);

      setMessages(_messages);
      setLoading(false);

      return _messages;
    } catch (error) {
      setMessages(null);
      setLoading(false);

      return null;
    }
  }, [user?._id]);

  const markRead = useCallback(
    async (IDs: string[] | string) => {
      try {
        if (!IDs?.length) return null;

        if (Array.isArray(IDs)) {
          const items: Promise<any>[] = [];

          IDs.forEach(id => items.push(putReadMessages(user?._id, id)));

          await Promise.all(items);
        } else {
          await putReadMessages(user?._id, IDs);
        }

        document.dispatchEvent(new CustomEvent('reload:messagesids'));
      } catch (error) {}
    },
    [user?._id],
  );

  const markPublicRead = useCallback(() => {
    if (active === 0 && !loadingMessagesIDs && unreadMessages.notificationsPublic > 0) {
      const IDs = messagesIDs?.public?.filter(x => !x.read).map(x => x._id);
      markRead(IDs || []);
    }
  }, [active, loadingMessagesIDs, markRead, messagesIDs?.public, unreadMessages.notificationsPublic]);

  const deleteItem = async (id: string) => {
    await delNotification(id);
    await getMessages();
    document.dispatchEvent(new CustomEvent('reload:messagesids'));
  };

  useEffect(() => {
    if (user) {
      getMessages();
    }
  }, [getMessages, user]);

  useEffect(() => {
    if (active === 0) {
      markPublicRead();
    }
  }, [active, markPublicRead]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [active]);

  const empty = (message: string) => {
    return (
      <>
        <h3 className="text-base py-28 text-center block">{message}</h3>
      </>
    );
  };

  if (loading || typeof messages === 'undefined' || typeof messagesIDs === 'undefined') {
    return (
      <div id="terms">
        <div className="grid gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
        </div>
      </div>
    );
  }

  return (
    <section id="messages">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Mensagens</h2>

      <div className="tabs-container">
        <div className="tabs-head w-full block">
          <ul className="flex items-center justify-between">
            <li className={`${active === 0 ? 'active' : ''}`}>
              <button onClick={() => setActive(0)}>
                Mensagens Públicas {unreadMessages.notificationsPublic > 0 && <span>({unreadMessages.notificationsPublic})</span>}
              </button>
            </li>
            <li className={`${active === 1 ? 'active' : ''}`}>
              <button onClick={() => setActive(1)}>
                Mensagens Privadas{' '}
                {unreadMessages.notificationsPrivate > 0 && <span>({unreadMessages.notificationsPrivate})</span>}
              </button>
            </li>
          </ul>
        </div>

        <div className="tabs-content">
          {/* Mensagens Públicas */}
          {active === 0 ? (
            <>
              {messages?.public?.length
                ? messages?.public.map(item => <ItemNotification key={item._id} item={item} type="public" markRead={markRead} />)
                : empty('Não existem mensagens públicas neste momento.')}
            </>
          ) : null}

          {/* Mensagens Privadas */}
          {active === 1 ? (
            <>
              {messages?.private?.length
                ? messages?.private.map(item => (
                    <ItemNotification key={item._id} item={item} type="private" markRead={markRead} deleteItem={deleteItem} />
                  ))
                : empty('Não existem mensagens privadas neste momento.')}
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
}
