import { useContext, createContext, useState, useEffect, useCallback } from 'react';
import { getBreadcrumb, getCourses, getDetailContent, getFolderProgress } from 'services';

import { ChildrenProp, CoursesInterface, NextLessonsInterface, ProgressInterface } from 'types';
import { useAuth } from './Auth.context';

export interface CoursesContextInterface {
  listCourses: CoursesInterface[] | undefined;
  allProgress: ProgressInterface[] | undefined;
  nextLessons: NextLessonsInterface[] | undefined;
  getFullProgress: () => Promise<ProgressInterface[]>;
}

const CoursesContext = createContext({} as CoursesContextInterface);

function CoursesProviderData(): CoursesContextInterface {
  const { user } = useAuth();

  const [listCourses, setListCourses] = useState<CoursesInterface[] | undefined>();
  const [allProgress, setAllProgress] = useState<ProgressInterface[] | undefined>();
  const [nextLessons, setNextLessons] = useState<NextLessonsInterface[] | undefined>();

  // Obter lista de cursos
  const getListCourses = useCallback(async (): Promise<CoursesInterface[]> => {
    try {
      const _courses = await getCourses(user?._id);
      setListCourses(_courses.length ? _courses : []);
      return _courses;
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [user]);

  // Obter progresso em todos os cursos
  const getFullProgress = useCallback(async (): Promise<ProgressInterface[]> => {
    try {
      if (!user?._id || !listCourses?.length) return [];

      let progress: any = [];

      listCourses.forEach((course: any) => {
        progress.push(getFolderProgress(user?._id, course._id));
      });

      await Promise.all(progress).then(data => {
        progress = data.filter(x => !!x);
      });

      setAllProgress(progress);

      return progress;
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [listCourses, user?._id]);

  // Processa os próximos conteúdos
  const processNextLessons = useCallback(async () => {
    if (typeof allProgress === 'object' && Array.isArray(allProgress)) {
      if (allProgress.length) {
        const findLessons = (items: ProgressInterface[]): ProgressInterface[] => {
          const obj: ProgressInterface[] = [],
            recursiveFn = (items: ProgressInterface[]) => {
              for (const item of items) {
                if (
                  item.folder &&
                  (item.type === 'topic' || item.type === 'class') &&
                  item.items?.length &&
                  !!item.items.find(x => !x.folder)
                ) {
                  obj.push({
                    ...item,
                    items: item.items.filter(x => !x.folder).sort((a, b) => (a.percent > b.percent ? -1 : 1)),
                  });
                }

                if (item.items) {
                  recursiveFn(item.items);
                }
              }
            };

          recursiveFn(items);

          return obj;
        };

        const coursesInProgress = allProgress.filter(x => x.percent > 0 && x.percent < 100);

        if (coursesInProgress.length) {
          const _nextLessons: any = [];

          coursesInProgress.forEach(course => {
            const lessons = course.items?.length
              ? findLessons([course])
                  .filter(lesson => lesson.percent < 100)
                  .map(lesson => {
                    return {
                      ...lesson,
                      items: lesson.items?.filter(x => x.percent < 100),
                    };
                  })
                  .filter(lesson => !!lesson.items?.length)
                  .sort((a, b) => (a.percent > b.percent ? -1 : 1))
              : null;

            if (lessons?.length) {
              const _item = {
                ...lessons[0],
                data: (lessons[0].items as ProgressInterface[])[0],
              };

              delete _item.items;

              _nextLessons.push(_item);
            }
          });

          if (_nextLessons.length) {
            const breadcrumbs: any = [];

            _nextLessons.forEach((lesson: any, index: any) => {
              breadcrumbs.push(
                getBreadcrumb(lesson._id)
                  .then(resp => {
                    _nextLessons[index].breadcrumbs = resp;
                  })
                  .catch(() => (_nextLessons[index].breadcrumbs = [])),
              );
            });

            await Promise.all(breadcrumbs);

            const detailContent: any = [];

            _nextLessons.forEach((lesson: any, index: any) => {
              detailContent.push(
                getDetailContent(lesson._id, true, true, { content: lesson.data.content }).then(resp => {
                  if (resp?.length) {
                    _nextLessons[index].data = resp[0];
                  }
                }),
              );
            });

            await Promise.all(detailContent);

            setNextLessons(_nextLessons);
          } else {
            setNextLessons([]);
          }
        } else {
          setNextLessons([]);
        }
      } else {
        setNextLessons([]);
      }
    } else {
      setNextLessons(undefined);
    }
  }, [allProgress]);

  useEffect(() => {
    if (Array.isArray(allProgress) && allProgress.length) {
      processNextLessons();
    }
  }, [allProgress, processNextLessons]);

  useEffect(() => {
    if (user?._id) {
      getListCourses();
    }
  }, [user, getListCourses]);

  useEffect(() => {
    if (!!listCourses?.length) {
      getFullProgress();
    }
  }, [listCourses, getFullProgress]);

  return {
    listCourses,
    allProgress,
    nextLessons,
    getFullProgress,
  };
}

const CoursesProvider = ({ children }: ChildrenProp) => {
  const contextData: CoursesContextInterface = CoursesProviderData();
  return <CoursesContext.Provider value={contextData}>{children}</CoursesContext.Provider>;
};

const useCourses = (): CoursesContextInterface => {
  const context = useContext(CoursesContext);

  if (typeof context === 'undefined') {
    throw new Error('useCourses must be used within an CoursesProvider');
  }

  return context;
};

export { CoursesProvider, useCourses };
