import { useState, useEffect } from 'react';
import { useAuth } from 'contexts';

import './style.scss';
import UserInfo from 'components/UserInfo';

export function Header() {
  const authContext = useAuth();
  const { user } = authContext;

  useEffect(() => {}, []);

  return <header>{user ? <UserInfo /> : null}</header>;
}
