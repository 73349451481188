import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { CoursesInterface } from 'types';
import { getCourseStatus } from 'utils';
import { useCourses } from 'contexts';

import './style.scss';

import noImage from 'assets/images/no-image.jpg';

export type CardCourseProps = {
  course: CoursesInterface;
  style?: React.CSSProperties;
  className?: string;
  [key: string]: any;
};

export function CardCourse({ course, style, className, ...rest }: CardCourseProps) {
  const { allProgress } = useCourses();

  const getPercent = useMemo(() => {
    let value = 0;

    if (allProgress?.length) {
      const coursePercent = allProgress.find(item => item._id === course._id);

      if (coursePercent) {
        value = coursePercent.percent;
      }
    }

    return value;
  }, [allProgress, course]);

  return (
    <div className={`card-course ${className || ''}`} style={style} {...rest}>
      <Link to={`/cursos/${course._id}`} className="relative flex items-center flex-nowrap">
        <div className="card-course__img overflow-hidden mr-3 lg:mr-5 flex items-center justify-center">
          <img
            src={course.extra?.image?.length ? course.extra?.image : noImage}
            alt={course.title || ' '}
            className="w-full h-full block object-cover transition-transform duration-300 ease-in-out"
          />
        </div>

        <div className="card-course__data">
          <h3 className="mb-2 text-black not-italic font-medium leading-tight">{course.title || ' '}</h3>
          {!!getCourseStatus(getPercent).length && (
            <p className="status text-sm lg:text-base not-italic font-bold leading-tight uppercase">
              {getCourseStatus(getPercent)}
            </p>
          )}
          {typeof getPercent === 'number' && getPercent > 0 && (
            <p className="progress text-sm lg:text-base text-black not-italic font-bold leading-tight">
              {`${Math.round(getPercent)}% concluído`}
            </p>
          )}
        </div>
      </Link>
    </div>
  );
}
