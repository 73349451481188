import { useState, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useAuth, useCourses } from 'contexts';
import { Placeholder } from 'components';
import { getCourseStatus } from 'utils';
import Tree from './Tree';
import { ProgressInterface } from 'types';

import './style.scss';

import noImage from 'assets/images/no-image.jpg';

export default function CoursesDetailPage() {
  const { user } = useAuth();
  const { allProgress, listCourses } = useCourses();
  const { courseID } = useParams();

  const [opened, setOpened] = useState<any>();

  const course = useMemo(() => {
    if (Array.isArray(listCourses) && listCourses.length) {
      const _course = listCourses.find(x => x._id === courseID);

      if (!!_course) {
        return _course;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [courseID, listCourses]);

  const courseProgress = useMemo(() => {
    if (Array.isArray(allProgress) && allProgress.length) {
      const _course = allProgress.find(x => x._id === courseID);

      if (!!_course) {
        return _course;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [allProgress, courseID]);

  if (!Array.isArray(listCourses)) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!course) {
    return <Navigate to={`/erro-404`} replace />;
  }

  return (
    <>
      <section id="courses-detail">
        <div className="infos">
          <div className="img overflow-hidden mr-5 flex items-center justify-center">
            <img
              src={course.extra?.image?.length ? course.extra?.image : noImage}
              alt={course.title || ' '}
              className="w-full h-full block object-cover transition-transform duration-300 ease-in-out"
            />
          </div>
          <div className="data">
            {!!course.title && <h2>{course.title}</h2>}

            {!!getCourseStatus(courseProgress?.percent).length && (
              <p className="status text-sm lg:text-base not-italic font-bold leading-tight uppercase">
                {getCourseStatus(courseProgress?.percent)}
              </p>
            )}
            {typeof courseProgress?.percent === 'number' && courseProgress?.percent > 0 && (
              <p className="progress text-sm lg:text-base text-black not-italic font-bold leading-tight">
                {`${Math.round(courseProgress?.percent)}% concluído`}
              </p>
            )}

            {!!course.extra?.description && (
              <div className="description text-sm" dangerouslySetInnerHTML={{ __html: course.extra.description }} />
            )}
          </div>
        </div>

        {!!courseProgress && !!courseID && (
          <div className="content">
            <h3>Conteúdo programático</h3>
            <Tree items={courseProgress?.items as ProgressInterface[]} parent={courseProgress._id} courseID={courseID} />
          </div>
        )}
      </section>
    </>
  );
}
