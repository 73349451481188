import { api } from './api';

type SignInRequestData = {
  username: string;
  password: string;
  apiKey?: string;
  grant_type?: string;
};

const APIKEY =
  !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
    ? (window as any).FUNIFIER_ENV?.apikey
    : process.env.REACT_APP_APIKEY;

export function signInRequest(data: SignInRequestData) {
  data['apiKey'] = APIKEY;
  data['grant_type'] = 'password';

  return api
    .post('/auth/token', data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // console.error(error.response.data.message);
      return error.response.data;
    });
}

export function fetchUser() {
  return api
    .get('/player/me/status')
    .then(response => {
      const user = response.data;
      if (user) {
        return user;
      } else {
        document.dispatchEvent(new CustomEvent('signout'));
      }
    })
    .catch(error => {
      // console.log(error);
    });
}

export function registerUser(user: any) {
  return api
    .put('/database/registration__c', user, {
      headers: {
        Authorization: 'Basic ' + btoa(`${APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`),
      },
    })
    .then(response => {
      const user = response.data;
      return user;
    })
    .catch(error => {
      // console.log(error);
    });
}

export async function recoverPasswordRequest(data: SignInRequestData) {
  data['apiKey'] = APIKEY;
  data['grant_type'] = 'password';

  try {
    const response = await api.post('/auth/token', data);
    return response.data.access_token;
  } catch (error) {
    console.log(error);
  }
}
