/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FaMedal } from 'react-icons/fa';
import { BsCoin } from 'react-icons/bs';
import { AiFillLock } from 'react-icons/ai';

import { Placeholder } from 'components';
import { getVirtualGoods } from 'services/store.service';
import { useAuth, useBasic } from 'contexts';
import ShopDetail from './Detail';

export default function RewardsPage() {
  const { user } = useAuth();
  const { pointsCategories } = useBasic();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 819px)' });

  function handleClick(good: any) {
    if (!good || !hasPermission(good)) return;
    navigate('/recompensas/' + good._id);
  }

  function hasPermission(good: any) {
    // return user?.extra.type === 'member' || good.extra.public
    return true;
  }

  function isItemPurchased(good: any) {
    return user?.catalog_items[good._id];
  }

  function getItemRequirements(good: any) {
    // console.log(good);

    const requirement = good.requires
      .filter((item: any) => item.type === 0)
      .reduce((acc: any, item: any) => {
        acc = item.total;
        return acc;
      }, -1);
    return requirement;
  }

  const getItemRequires = (require: any) => {
    const pointObj = pointsCategories?.find(item => item._id === require.item);

    return (
      <p
        key={`${require.item}_${require.total}`}
        className="w-full flex items-center justify-center rounded-xl py-1 px-2.5"
        title={pointObj?.category}
      >
        <strong className="mr-1 not-italic font-bold text-sm leading-none text-purple">{require.total}</strong>
        {!!pointObj?.image ? (
          <>
            <img src={pointObj.image} alt={pointObj.category} className="w-4 inline-block" />
          </>
        ) : (
          <>
            {!!pointObj?._id?.match(/xp/gi) ? (
              <FaMedal className="text-base text-purple inline-block" />
            ) : (
              <BsCoin className="text-base text-purple inline-block" />
            )}
          </>
        )}
      </p>
    );
  };

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    async function loadData() {
      const data = await getVirtualGoods();
      const items = data.filter((item: any) => {
        return true;
      });
      setData(items);
      setLoading(false);
    }

    loadData();
  }, [user]);

  if (!user) return null;

  return (
    <>
      <div className="flex-1">
        <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Loja de Recompensas</h2>

        {/* <Link to="./teste">ABRIR TESTE</Link> */}

        {(loading || !data) && (
          <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-3">
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        )}

        {!loading && data && data.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-3">
            {data.map((good: any) => (
              <div
                key={good._id}
                className={`cursor-pointer flex flex-col justify-start items-center`}
                onClick={() => {
                  handleClick(good);
                }}
              >
                <div className="flex-0 h-40 w-full bg-gray-100 rounded-md overflow-hidden">
                  {good.image && <img src={good.image?.medium?.url} alt="item" className="w-full h-full object-contain" />}
                </div>
                <p className="flex items-center justify-center leading-4 text-center p-2 text-sm">{good.name}</p>
                {!isItemPurchased(good) && (
                  <div className="flex-1 flex items-center justify-center text-center font-bold">
                    {!hasPermission(good) ? (
                      <AiFillLock size={24} className="mr-2" />
                    ) : getItemRequirements(good) > 0 ? (
                      <div className="flex flex-col items-center text-center">
                        {good.requires.map((require: any) => getItemRequires(require))}
                      </div>
                    ) : (
                      <p className="text-yellow-500">Trocar</p>
                    )}
                  </div>
                )}
                {isItemPurchased(good) && (
                  <p className="flex itemd-center justify-center text-center text-sm text-green-500 font-bold">Trocado</p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {params.id ? <ShopDetail /> : null}
    </>
  );
}
