import { UIElementProps } from 'types/prop-types';

type ButtonProps = UIElementProps & {
  children: React.ReactNode;
  onClick?: (e: any) => void;
  href?: string;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  tag?: 'button' | 'a' | 'input';
  style?: React.CSSProperties;
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'outline';
  [key: string]: any;
};

const buttonColor: any = {
  primary: 'bg-white text-blue-800 ring-2 ring-white ring-inset',
  danger: 'bg-red-600 text-white ring-2 ring-white',
  success: 'bg-green-600 text-white ring-2 ring-white',
  outline: 'bg-transparent text-yellow ring-2 ring-yellow',
};

export default function Button(props: ButtonProps) {
  const { children, className, color } = props;

  return (
    <button
      {...props}
      className={`${
        buttonColor[color || 'primary']
      } hover:ring hover:ring-blue-600 rounded-full border shadow-xl font-bold px-4 ${className}`}
    >
      <div className="p-1">{children}</div>
    </button>
  );
}
