import { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth, useBasic, useToast } from 'contexts';

import './style.scss';

import Logo from 'assets/images/playdps.png';
import { SubmitHandler, useForm } from 'react-hook-form';

type InputsForm = {
  username: string;
  password: string;
};

export default function AuthPage() {
  const { isAuthenticated, loginWithToken, login } = useAuth();
  const { setCustomClass } = useBasic();
  const { toastify } = useToast();

  const {
    register,
    handleSubmit: submit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputsForm>();

  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  // const [errors, setErrors] = useState<any>({});

  const doLogin = useCallback(
    async (username: string, password: string) => {
      setLoading(true);

      try {
        await login({ username, password });
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);

        toastify({
          content: 'Usuário ou senha inválidos. Por favor, tente novamente.',
          type: 'error',
        });
      }
    },
    [login, toastify],
  );

  const doLoginWithToken = useCallback(
    async (token: string) => {
      try {
        await loginWithToken(token);
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    },
    [loginWithToken],
  );

  useEffect(() => {
    setCustomClass('auth');

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const agent = params.get('agent');
    const secret = params.get('secret');

    if (token) {
      setLoading(true);
      doLoginWithToken(token);
    } else if (agent && secret) {
      setLoading(true);
      doLogin(agent, secret);
    } else {
      setInvalidUser(true);
    }

    return () => setCustomClass('');
  }, [doLogin, doLoginWithToken, login, loginWithToken, setCustomClass]);

  const handleSubmit: SubmitHandler<InputsForm> = async data => {
    if (loading) return;
    doLogin(data.username, data.password);
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div
      id="auth"
      className="absolute left-0 right-0 min-h-full m-auto px-5 md:w-1/2 xl:w-1/3 2xl:w-1/4 flex flex-col items-center justify-center"
    >
      <div>
        <img src={Logo} className="pointer-events-none" alt="PlayDPS :: Games for Data Privacy & Security" />
      </div>

      {loading && <div className="w-full border p-3 mt-8 rounded-full text-center font-bold bg-white">CARREGANDO...</div>}

      {invalidUser && !loading && (
        <form action="" onSubmit={submit(handleSubmit)} noValidate>
          <ul>
            <li>
              <input
                type="text"
                id="username"
                aria-invalid={errors.username ? 'true' : 'false'}
                {...register('username', {
                  required: 'Campo obrigatório.',
                })}
                disabled={loading}
                placeholder="Usuário"
              />
              {errors.username && <span role="alert">{errors.username.message}</span>}
            </li>
            <li>
              <input
                type="password"
                id="password"
                aria-invalid={errors.password ? 'true' : 'false'}
                {...register('password', {
                  required: 'Campo obrigatório.',
                })}
                disabled={loading}
                placeholder="Senha"
              />
              {errors.password && <span role="alert">{errors.password.message}</span>}
            </li>
          </ul>

          <button type="submit" disabled={loading}>
            Entrar
          </button>
        </form>
        // <div className="w-full border p-3 mt-8 rounded-full text-center font-bold bg-white">VOCÊ NÃO TEM ACESSO AO SITE</div>
      )}
    </div>
  );
}
