import { ChallengeInterface } from 'types';
import { api } from './api';

export async function getChallenges(): Promise<ChallengeInterface[]> {
  const { data } = await api.get(`challenge`, {
    params: { orderby: 'extra.order', reverse: true },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter(item => item.techniques.includes('GT35'));
  }
}

export async function getAllChallenges(filters?: any) {
  const { data } = await api.get(`challenge`, {
    params: { orderby: 'extra.order', reverse: true, q: "'extra.hidden':null,active:true" },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}
