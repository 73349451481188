import { MessagesFullInterface, MessagesIDsInterface } from 'types';
import { api } from './api';

export async function getMessagesIDs(player: string | undefined): Promise<MessagesIDsInterface | null> {
  if (!player) return null;

  const { data } = await api.post('find/messages_ids', { player });
  return Array.isArray(data) && data.length ? data[0] : null;
}

export async function getAllMessages(player: string | undefined): Promise<MessagesFullInterface | null> {
  if (!player) return null;

  const { data } = await api.post('find/messages_full', { player });
  return Array.isArray(data) && data.length ? data[0] : null;
}

export async function putReadMessages(userId: string | undefined, itemId: string) {
  if (!userId?.length || !itemId?.length) return null;

  const payload: any = {
    created_at: +new Date(),
    type: 'notifications',
    userId,
    itemId,
  };

  const { data } = await api.put('database/read_content__c', payload);
  return data;
}
