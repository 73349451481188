import { BsThreeDots } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { CardLesson } from 'components';
import { NextLessonsInterface } from 'types';

import './style.scss';

import noLessons from 'assets/images/home-no-lessons.jpg';

export type BoxNextLessonsProps = {
  lessons: NextLessonsInterface[];
  title?: string;
  maxLength?: number;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  [key: string]: any;
};

export function BoxNextLessons({ lessons, title, maxLength, children, className, style, ...rest }: BoxNextLessonsProps) {
  return (
    <section className={`box-next-lessons ${className || ''}`} style={style} {...rest}>
      {!!title?.length && (
        <div className="box-next-lessons__head flex justify-between items-center mb-3 lg:mb-7">
          <h2 className="font-medium">{title}</h2>

          <div className="box-next-lessons__actions hidden lg:block">
            {!!lessons.length && (
              <button>
                <BsThreeDots />
              </button>
            )}
          </div>
        </div>
      )}

      {!!lessons.length && (
        <div className={`box-next-lessons__cards block lg:flex justify-between items-stretch lg:-mx-5`}>
          {lessons.slice(0, typeof maxLength === 'number' ? maxLength : lessons.length).map((lesson, index, obj) => (
            <CardLesson key={lesson._id} lesson={lesson} className={`${obj.length > 1 ? 'w-full lg:w-2/4' : 'w-full'} lg:mx-5`} />
          ))}
        </div>
      )}

      {!lessons.length && (
        <div className={`box-next-lessons__cards block lg:flex justify-between items-stretch`}>
          <Link to={'/cursos'} className="block w-full h-auto no-underline p-0 m-0">
            <img src={noLessons} alt={title || ''} className="block w-full h-auto p-0 m-0" />
          </Link>
        </div>
      )}

      {children}
    </section>
  );
}
