import { useCourses } from 'contexts';
import { BoxCourses } from 'components';
import { Placeholder } from 'components';

import './style.scss';

export default function CoursesPage() {
  const { listCourses } = useCourses();

  if (!Array.isArray(listCourses)) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!listCourses.length) {
    return (
      <>
        <h2 className="block text-center p-6">Nenhum curso encontrado.</h2>
      </>
    );
  }

  return (
    <>
      <BoxCourses courses={listCourses} title="Cursos" classNameCards="flex-wrap" />
    </>
  );
}
