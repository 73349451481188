import axios from 'axios';

import { setupInterceptors } from './interceptors';

export function getAPIClient(ctx?: any) {
  const urlService =
    !!(window as any).FUNIFIER_ENV?.service && typeof (window as any).FUNIFIER_ENV?.service === 'string'
      ? (window as any).FUNIFIER_ENV?.service + '/v3'
      : process.env.REACT_APP_API_URL;

  const api = setupInterceptors(
    axios.create({
      baseURL: urlService,
    }),
  );

  return api;
}
