import { useEffect, useState } from 'react';

import { useAuth, useCourses } from 'contexts';
import { registerFolderLog } from 'services';
import { DetailContentInterface } from 'types';

import './style.scss';

type TextProps = {
  content: DetailContentInterface;
  className?: string;
  [key: string]: any;
};

export function Text({ content, className, ...rest }: TextProps) {
  const { user } = useAuth();
  const { getFullProgress } = useCourses();

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (!done) {
      setDone(true);

      registerFolderLog(user?._id, content._id, 'done').finally(() => {
        getFullProgress();
      });
    }
  }, [content, done, getFullProgress, user]);

  if (!content || !content.data?.text) return null;

  return (
    <div
      className={`text-component w-full relative bg-white ${className || ''}`}
      dangerouslySetInnerHTML={{ __html: (content.data.text as string) || '' }}
    />
  );
}
