import { Routes, Route } from 'react-router-dom';
import RoutesLayout from './RoutesLayout';
import Layout from '../pages/Layout';

export default function MyRoutes() {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Layout>
            <RoutesLayout />
          </Layout>
        }
      />
    </Routes>
  );
}
