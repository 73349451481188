import { api } from './api';

export interface Extra {}

export interface Choice {
  answer: string;
  grade: number;
  extra: Extra;
}

export interface I18n {}

export interface Extra2 {
  owner: string;
  cost: number;
}

export interface QuizType {
  _id: string;
  type: string;
  title: string;
  question: string;
  grade: number;
  choices: Choice[];
  i18n: I18n;
  techniques: string[];
  select: string;
  feedbacks: any[];
  extra: Extra2;
  created: any;
  updated: any;
  requires: any[];
  action: string;
  log: number;
  fund: number;
  fund_size: number;
  owner_name: string;
}

export async function getQuiz(player: string) {
  const { data } = await api.post<[QuizType]>('/find/quiz_quests', { player });

  return data;
}

export async function getQuestionsQuiz(quiz: any) {
  const { data } = await api.post('database/question/aggregate', [{ $match: { quiz: quiz } }]);

  return data;
}

export async function sendQuestionsQuiz(player: string, answer: string, question: string) {
  const payload = {
    player,
    question: question,
    answer: [answer],
  };

  const { data } = await api.post('question/log?async=false', payload);

  return data;
}
