import { useNavigate } from 'react-router-dom';
import { BiLock } from 'react-icons/bi';

import { GameInterface } from 'types/games';

import './style.scss';

const imageCruzadinha = require('assets/images/games/crosswords.jpg');
const imageWordfinder = require('assets/images/games/wordfinder.jpg');
const imageQuiz = require('assets/images/games/quiz.jpg');
const imageRunner = require('assets/images/games/space_runner.jpg');
const imageEnigma = require('assets/images/games/enigma.jpg');
const imageCodex = require('assets/images/games/codex.jpg');
const imageAdventurePhishing = require('assets/images/games/adventure-phishing.jpg');
const imageAdventureMaoFantasma = require('assets/images/games/adventure-maofantasma.jpg');
const imageAdventureBreach = require('assets/images/games/adventure-breach.jpg');

export const gamesList: GameInterface[] = [
  {
    id: 'crosswords',
    title: 'Cruzadinha',
    image: imageCruzadinha,
    description: 'Preencha as palavras de acordo com as dicas',
    howToPlay: 'Use seu teclado para preencher os blocos, apagar ou mudar de linha.',
  },
  {
    id: 'wordsearch',
    title: 'Caça-Palavras',
    image: imageWordfinder,
    description: 'Encontre as palavras escondidas',
  },
  // {
  //   id: 'quiz',
  //   title: 'Quiz',
  //   image: imageQuiz,
  //   description: "Teste seus conhecimentos em um jogo de pergunta e resposta"
  // },

  {
    id: 'codex',
    title: 'CODEX',
    image: imageCodex,
    description: 'Encontre as palavras embaralhadas',
  },
  {
    id: 'runner',
    title: 'Corrida Espacial',
    image: imageRunner,
    description: 'Colete moedas e ganhe bônus ao responder as perguntas corretamente',
    locked: true,
  },
  {
    id: 'enigma',
    title: 'Enigma',
    image: imageEnigma,
    description: 'Descubra a resposta escondida',
    locked: true,
  },
];

export const gamesNarrativeList: GameInterface[] = [
  {
    id: 'adventure-phishing',
    title: 'Ataque de Phishing',
    image: imageAdventurePhishing,
    description: 'História Interativa',
    locked: true,
  },
  {
    id: 'phishing',
    title: 'Um clique perigoso',
    image: imageAdventureMaoFantasma,
    description: 'História Interativa',
  },
  {
    id: 'escape-data-breach',
    title: 'Vazamento de Dados',
    image: imageAdventureBreach,
    description: 'Jogo de Investigação',
    locked: true,
  },
];

export default function GamesPage() {
  const navigate = useNavigate();

  function handleClick(item: GameInterface) {
    if (item.locked) return;
    navigate(`./${item.id}`);
  }

  return (
    <>
      <h2 className="text-xl lg:text-3xl mb-10">Divirta-se e aprenda mais sobre Segurança da Informação e LGPD.</h2>
      <h3 className="text-xl lg:text-3xl mb-10">Games Casuais</h3>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
        {gamesList.map(item => (
          <div
            key={item.id}
            className={`game-list-item flex flex-col lg:flex-row justify-center lg:justify-start items-center p-3 lg:p-0 shadow-xl rounded-xl overflow-hidden ${
              !item.locked ? 'cursor-pointer hover:ring-2' : ''
            } `}
          >
            <div className="mb-5 lg:mb-0 w-24 lg:w-32">
              <img src={item.image} alt={item.title} className="rounded-md lg:rounded-xl" />
            </div>
            <div className="space-y-2 px-5 lg:px-10 flex-1 text-center lg:text-left">
              <h4 className="text-base lg:text-xl">{item.title}</h4>
              <p className="text-gray-500">{item.description}</p>
            </div>
            <div className="p-5 lg:p-10">
              {!item.locked && (
                <button onClick={e => handleClick(item)} className="border rounded-full p-2 px-4">
                  JOGAR
                </button>
              )}
              {item.locked && (
                <button disabled className="border rounded-full p-2 px-4">
                  <BiLock size={20} />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <h3 className="text-xl lg:text-3xl my-5">Games de Narrativa e Simuladores</h3>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
        {gamesNarrativeList.map(item => (
          <div
            key={item.id}
            className={`game-list-item flex flex-col lg:flex-row justify-center lg:justify-start items-center p-3 lg:p-0 shadow-xl rounded-xl overflow-hidden ${
              !item.locked ? 'cursor-pointer hover:ring-2' : ''
            } `}
          >
            <div className="mb-5 lg:mb-0 w-24 lg:w-32">
              <img src={item.image} alt={item.title} className="rounded-md lg:rounded-xl" />
            </div>
            <div className="space-y-2 px-5 lg:px-10 flex-1 text-center lg:text-left">
              <h4 className="text-base lg:text-xl">{item.title}</h4>
              <p className="text-gray-500">{item.description}</p>
            </div>
            <div className="p-5 lg:p-10">
              {!item.locked && (
                <button onClick={e => handleClick(item)} className="border rounded-full p-2 px-4">
                  JOGAR
                </button>
              )}
              {item.locked && (
                <button disabled className="border rounded-full p-2 px-4">
                  <BiLock size={20} />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
