import { ChildrenProp } from 'types';
import {
  AuthProvider,
  BasicProvider,
  CoursesProvider,
  IconProvider,
  ModalProvider,
  ToastProvider,
  PrivateProvider,
  WebsocketProvider,
} from 'contexts';

export function AppProvider(props: ChildrenProp) {
  const providers = [
    AuthProvider,
    BasicProvider,
    IconProvider,
    ModalProvider,
    ToastProvider,
    CoursesProvider,
    WebsocketProvider,
    PrivateProvider,
  ];
  const { children } = props;

  return (
    <>
      {providers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
}
