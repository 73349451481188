import { Link } from 'react-router-dom';

import PATHS from 'routes/paths';

import './style.scss';

export default function SettingsPage() {
  return (
    <section id="settings">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Configurações</h2>

      <div className="pages flex justify-between flex-wrap">
        {PATHS.find(route => route.id === 'settings')?.children?.map(page => {
          return (
            <div className="page" key={page.id}>
              <Link to={page.pathname} className="flex items-center justify-between p-2 bg-white rounded-xl border">
                <span className="img">{page.icon}</span>
                <span className="text">{page.title}</span>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
}
