import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { BsCheck2Square, BsTrash } from 'react-icons/bs';
import { CgUndo } from 'react-icons/cg';
import { AiOutlineCheckCircle, AiOutlineLoading3Quarters } from 'react-icons/ai';

import { useBasic } from 'contexts';
import { NotificationsDetailInterface } from 'types';

import noImage from 'assets/images/no-image.jpg';

type ItemNotificationProps = {
  item: NotificationsDetailInterface;
  type: string;
  markRead: (value: string[]) => void;
  deleteItem?: (value: string) => void;
};

export default function ItemNotification({ item, type, markRead, deleteItem }: ItemNotificationProps) {
  const { messagesIDs, loadingMessagesIDs, setLoadingMessagesIDs } = useBasic();

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [processDelete, setProcessDelete] = useState<boolean>(false);

  const isNew = useMemo(() => {
    return ![...(messagesIDs?.private || []), ...(messagesIDs?.public || [])].find(x => x._id === item._id)?.read;
  }, [item._id, messagesIDs?.private, messagesIDs?.public]);

  const handleRead = () => {
    setLoadingMessagesIDs(true);
    markRead([item._id]);
  };

  const handleDelete = () => {
    setShowAlert(false);

    if (typeof deleteItem === 'function') {
      setLoadingMessagesIDs(true);
      deleteItem(item._id);
    }
  };

  return (
    <div
      className={`item-notification flex items-center justify-between w-full ${isNew ? 'new' : ''} ${type} ${
        showAlert ? 'alert' : ''
      }`}
    >
      <span className="ico rounded-full"></span>

      <span className="image flex items-center justify-center rounded-full overflow-hidden">
        {!!item.item?.image ? <img src={item.item?.image} alt={item.item?.name || ''} /> : <img src={noImage} alt=" " />}
      </span>

      <div className="infos">
        <p className="text">{item.content}</p>
        <p className="date">
          {format(item.time, type === 'private' ? "'Em' dd 'de' MMMM 'de' yyyy', às' HH:mm'h'" : "'Em' dd 'de' MMMM 'de' yyyy", {
            locale: ptBR,
          })}
        </p>
      </div>

      {type === 'private' && (
        <div className="actions flex items-center justify-end">
          {isNew && !showAlert && (
            <button
              className="mark-read"
              title="Marcar como lida"
              disabled={loadingMessagesIDs}
              onClick={() => (!loadingMessagesIDs ? handleRead() : null)}
            >
              {loadingMessagesIDs ? <AiOutlineLoading3Quarters className="loading" /> : <BsCheck2Square />}
            </button>
          )}
          <button
            className="trash"
            title="Excluir"
            disabled={loadingMessagesIDs}
            onClick={() => (!loadingMessagesIDs && !showAlert ? setShowAlert(true) : null)}
          >
            {showAlert ? (
              <span className="alert">
                Confirma exclusão?
                <span className="buttons">
                  <span className="no" onClick={() => setShowAlert(false)} title="Cancelar">
                    <CgUndo />
                  </span>
                  <span className="yes" onClick={() => handleDelete()} title="Sim">
                    <AiOutlineCheckCircle />
                  </span>
                </span>
              </span>
            ) : loadingMessagesIDs ? (
              <AiOutlineLoading3Quarters className="loading" />
            ) : (
              <BsTrash />
            )}
          </button>
        </div>
      )}
    </div>
  );
}
