import { useEffect, useState } from 'react';

import { Placeholder } from 'components';
import { getFaq } from 'services';
import { FaqInterface } from 'types';
import ItemFaq from './ItemFaq';

import './style.scss';

export default function FaqPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<FaqInterface[]>();

  useEffect(() => {
    getFaq()
      .then(_data => setData(_data))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div id="terms">
        <div className="grid gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
        </div>
      </div>
    );
  }
  return (
    <section id="faq">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">FAQ</h2>

      <div className="accordion">
        {data?.map(item => (
          <ItemFaq item={item} key={item._id} />
        ))}
      </div>
    </section>
  );
}
