import { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';

import { useAuth } from 'contexts';
import { BoardsInterface, getLeaderboard, getLeaderboards, getPlayerPosition, LeaderboardInterface } from 'services';

import './style.scss';

export default function RankingPage() {
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);

  const [boards, setBoards] = useState<LeaderboardInterface[]>([]);
  const [activeBoardIndex, setActiveBoardIndex] = useState<number>();
  const [leaderboardsIDs, setLeaderboardsIDs] = useState<string[]>();
  const [requestCounter, setRequestCounter] = useState<number>(0);

  const activeLeaderboard = useMemo(() => {
    return boards[activeBoardIndex || 0];
  }, [activeBoardIndex, boards]);

  const initialLoad = useCallback(async () => {
    if (!user || !!leaderboardsIDs) return;

    setLoading(true);
    setRequestCounter(0);

    const leaderboards: BoardsInterface[] = await getLeaderboards();

    if (leaderboards?.length) {
      const IDs = leaderboards.map(x => x._id);

      setLeaderboardsIDs(IDs);

      const promises: any[] = [];

      IDs.forEach(id => {
        promises.push(
          getLeaderboard(id, user._id, 10)
            .then(async leaderboard => {
              setRequestCounter(prevNumber => prevNumber + 1);

              try {
                const myPosition = await getPlayerPosition(
                  leaderboard.leaderboard._id,
                  user._id,
                  leaderboard.leaders[0]?.extra?.cache || undefined,
                );

                setRequestCounter(prevNumber => prevNumber + 1);

                return { ...leaderboard, my_position: myPosition || null };
              } catch {
                setRequestCounter(prevNumber => prevNumber + 1);
                return null;
              }
            })
            .catch(() => {
              setRequestCounter(prevNumber => prevNumber + 1);
              return null;
            }),
        );
      });

      Promise.all(promises)
        .then(response => {
          const _boards = response.filter(x => !!x && !!x.leaders && !!x.leaders.length);

          if (_boards?.length) {
            setBoards(_boards);
            setActiveBoardIndex(0);
          }
        })
        .catch(() => null)
        .finally(() => setLoading(false));
    } else {
      return setLoading(false);
    }
  }, [leaderboardsIDs, user]);

  const paginateIsInactive = (direction: 'prev' | 'next') => {
    if (!boards || typeof activeBoardIndex !== 'number' || boards.length <= 1) return true;

    if (direction === 'prev') {
      return activeBoardIndex === 0 ? true : false;
    } else {
      return activeBoardIndex + 1 === boards.length ? true : false;
    }
  };

  useEffect(() => {
    if (!user || !!leaderboardsIDs) return;

    initialLoad();
  }, [user, initialLoad, leaderboardsIDs]);

  function renderLoading() {
    return (
      <>
        <p className="loading text-2xl lg:text-4xl text-center bg-white/80 shadow-xl p-6 lg:p-12 rounded-xl backdrop-blur-md">
          <span className="block mb-3 text-base lg:text-lg">Carregando...</span>
          {!!leaderboardsIDs ? Math.round((requestCounter * 100) / (leaderboardsIDs.length * 2)) : '0'}%
        </p>
        {/* <ArticlePlaceholder /> */}
      </>
    );
  }

  function noResult() {
    return (
      <h2 className="text-xl lg:text-3xl bg-white/80 shadow-xl p-4 rounded-xl backdrop-blur-md">
        Não existem competições em andamento
      </h2>
    );
  }

  function renderList() {
    if (!boards || !boards.length) {
      return noResult();
    }

    const showMyPosition = () => {
      if (!activeLeaderboard?.my_position) return null;

      return (
        <div className="my-position flex items-center justify-between bg-gray-50 rounded-2xl w-full py-3 px-6 lg:px-11 mb-5 text-gray-900">
          <p className="position">
            <span>Posição</span>
            {activeLeaderboard.my_position.position}
          </p>
          <div className="user flex flex-col items-center justify-center">
            <span
              className={`img ${
                !!user?.image?.medium?.url ? 'has-image' : 'no-image'
              } w-9 lg:w-16 h-9 lg:h-16 flex items-center justify-center rounded-full overflow-hidden border border-white border-solid mb-1.5`}
            >
              {!!user?.image?.medium?.url ? (
                <>
                  <img src={user?.image?.medium?.url} alt={user?.name} className="h-full w-full block object-cover" />
                </>
              ) : (
                <>
                  <BiUser className="w-full h-full bg-white" />
                </>
              )}
            </span>
            <p>{user?.name}</p>
          </div>

          <p className="rate">
            <span>EXP</span>
            {activeLeaderboard.my_position.total}
          </p>
        </div>
      );
    };

    return (
      <div className="boards backdrop-blur-md flex flex-col py-0 w-full h-full">
        <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Ranking</h2>

        <div className="head flex items-start justify-between pb-4 lg:pb-6 w-full lg:pr-4">
          <button
            onClick={() =>
              paginateIsInactive('prev') ? null : setActiveBoardIndex(prev => (typeof prev === 'number' ? prev - 1 : 0))
            }
            disabled={paginateIsInactive('prev')}
            className={`btn-prev text-base lg:text-xl leading-none mt-1.5 ${
              paginateIsInactive('prev') ? 'opacity-0 pointer-events-none' : 'cursor-pointer'
            }`}
          >
            <FaAngleLeft />
          </button>
          <div className="board-title flex flex-col items-center justify-start">
            <h4 className="not-italic text-xl lg:text-2xl font-bold text-center mb-1">{activeLeaderboard?.leaderboard.title}</h4>
            {!!activeLeaderboard?.expiration_datetime && (
              <p className="not-italic font-normal text-sm lg:text-base text-center">
                Este ranking reiniciará em {moment(activeLeaderboard?.expiration_datetime).format('DD [de] MMMM')}
              </p>
            )}
          </div>
          <button
            onClick={() =>
              paginateIsInactive('next') ? null : setActiveBoardIndex(next => (typeof next === 'number' ? next + 1 : 0))
            }
            disabled={paginateIsInactive('next')}
            className={`btn-next text-base lg:text-xl leading-none mt-1.5 ${
              paginateIsInactive('next') ? 'opacity-0 pointer-events-none' : 'cursor-pointer'
            }`}
          >
            <FaAngleRight />
          </button>
        </div>

        {!activeLeaderboard?.leaders.length ? (
          <div className={`list flex-1 w-full lg:pr-4 flex items-center justify-center`}>{noResult()}</div>
        ) : (
          <div className={`list ${!!showMyPosition() ? 'has-position' : 'no-position'} flex-1 w-full lg:pr-4`}>
            {showMyPosition()}

            <ul className="bg-gray-50 rounded-2xl w-full px-2 lg:px-4 py-3 lg:py-5">
              {activeLeaderboard?.leaders.map(leader => (
                <li key={leader._id}>
                  <div
                    className={`${
                      leader.player === user?._id ? 'active text-white' : 'text-gray-900'
                    } flex items-center justify-between py-3 px-5 lg:px-6 rounded-full`}
                  >
                    <p className="position font-bold flex flex-col justify-center items-center">
                      <span>Posição</span>
                      {leader.move === 'up' && <FaAngleUp className="up" />}
                      {leader.position}
                      {leader.move === 'down' && <FaAngleDown className="down" />}
                    </p>

                    <div className="user flex items-center justify-start flex-1">
                      <span
                        className={`img ${
                          !!leader.image ? 'has-image' : 'no-image'
                        } w-9 h-9 flex items-center justify-center rounded-full overflow-hidden border border-white border-solid mr-4 text-gray-900`}
                      >
                        {!!leader.image ? (
                          <>
                            <img src={leader.image} alt={leader.name} className="h-full w-full block object-cover" />
                          </>
                        ) : (
                          <>
                            <BiUser className="w-full h-full bg-white" />
                          </>
                        )}
                      </span>
                      <p>{leader.name}</p>
                    </div>

                    <p className="rate flex flex-col justify-center items-center">
                      <span>EXP</span>
                      {leader.total}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  return (
    <section id="ranking-wrapper" className="rounded-3xl flex flex-row items-center justify-center">
      {loading ? renderLoading() : renderList()}
    </section>
  );
}
