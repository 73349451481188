/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useRef } from 'react';

export function useEvent(event: any, handler: any, element = window) {
  const savedHandler = useRef<any>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(event, eventListener);
    return () => element.removeEventListener(event, eventListener);
  }, [event, element]);
}
