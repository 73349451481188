import { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';

import { GameContainer } from 'components/GameContainer';
import { gamesList, gamesNarrativeList } from '../Games';
import { useBasic } from 'contexts';

import './style.scss';

export default function GamesDetailPage() {
  const { setShowHeader } = useBasic();
  const { gameID } = useParams();

  const gameData = useMemo(() => {
    if (!gameID) return null;

    const gameObj = [...gamesList, ...gamesNarrativeList].find(game => game.id === gameID);

    if (!gameObj) return null;

    return gameObj;
  }, [gameID]);

  useEffect(() => {
    setShowHeader(false);

    return () => setShowHeader(true);
  }, [setShowHeader]);

  if (!gameID) return null;

  return (
    <div id="games-detail" className="flex flex-col h-full gap-4">
      <div
        className={`head block lg:flex justify-between w-full ${
          !!gameData?.description?.length && !!gameData?.howToPlay?.length ? 'items-start' : 'items-center'
        }`}
      >
        <Link to="../" className="back">
          <span className="ico">
            <HiArrowLeft />
          </span>
          Voltar
        </Link>

        <div className={`crumbs has-btn-back text-right`}>
          {!!gameData && (
            <p>
              <strong>{gameData.title}</strong>
              {!!gameData.description?.length && !!gameData.howToPlay?.length && (
                <span className="title">{gameData.description}</span>
              )}
            </p>
          )}
        </div>
      </div>

      <GameContainer id={gameID} />

      {(!!gameData?.howToPlay?.length || !!gameData?.description?.length) && (
        <div>
          <h5 className="font-bold">Como Jogar:</h5>
          <p>{gameData.howToPlay || gameData.description || ''}</p>
        </div>
      )}
    </div>
  );
}
