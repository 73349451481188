import { CardCourse } from 'components';
import { BsThreeDots } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

import { CoursesInterface } from 'types';

import './style.scss';

export type BoxCoursesProps = {
  courses: CoursesInterface[];
  title: string;
  maxLength?: number;
  children?: React.ReactNode;
  className?: string;
  classNameCards?: string;
  style?: React.CSSProperties;
  [key: string]: any;
};

export function BoxCourses({ courses, title, maxLength, children, className, classNameCards, style, ...rest }: BoxCoursesProps) {
  const location = useLocation();

  return (
    <section className={`box-courses ${className || ''}`} style={style} {...rest}>
      {!!title?.length && (
        <div className="box-courses__head flex justify-between items-center mb-3 lg:mb-7">
          <h2 className="font-medium">
            {title} <span>({courses.length})</span>
          </h2>

          <div className="box-courses__actions hidden lg:block">
            {!!courses.length && (
              <button>
                <BsThreeDots />
              </button>
            )}
          </div>
        </div>
      )}

      {!!courses.length && (
        <>
          <div className={`box-courses__cards block lg:flex justify-between items-stretch lg:-mx-5 ${classNameCards || ''}`}>
            {courses.slice(0, typeof maxLength === 'number' ? maxLength : courses.length).map((course, index) => (
              <CardCourse
                key={course._id}
                course={course}
                className={`${courses.length > 1 ? 'w-full lg:w-2/4' : 'w-full'} lg:p-5 mb-3 lg:mb-0`}
              />
            ))}
          </div>

          {/* {courses.length > 2 && !location.pathname.match(/cursos/gi) ? (
            <Link to={`/cursos`} className="relative flex items-center justify-center lg:hidden">
              Ver todos os cursos
            </Link>
          ) : null} */}
        </>
      )}

      {children}
    </section>
  );
}
