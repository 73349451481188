import { api } from './api';

export async function getAchievements(filters?: any) {
  const payload = [];

  if (filters) {
    payload.push(filters);
  }

  payload.push(
    {
      $sort: {
        time: -1,
      },
    },
    {
      $limit: 50,
    },
  );

  const { data } = await api.post(`database/achievement/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}
