import { ProgressInterface } from 'types';

export function newDate(dateStr?: string | Date) {
  if (dateStr) {
    const str = String(dateStr);
    const arr = str.split('.');
    return new Date(arr[0]);
  }
  return new Date();
}

// Function GetNameAbb
// João Souza Alok => JA
export function getNameAbb(name: string) {
  if (!name) {
    return '';
  }

  const nameBreak = name.split(' ');
  if (nameBreak.length === 1) {
    return nameBreak[0].charAt(0) + nameBreak[0].charAt(1);
  }
  let secondName = nameBreak[1].charAt(0);
  if (nameBreak.length >= 3) {
    secondName = nameBreak[1].length >= 3 ? nameBreak[1].charAt(0) : nameBreak[2].charAt(0);
  }
  return nameBreak[0].charAt(0) + secondName;
}

// Function GetNameAbb
// Victor de Latre Novais => Victor Latre
export function getAbbreviatedName(name: string) {
  if (!name) {
    return '';
  }
  const nameBreak = name.split(' ');
  let _name = '';
  const deleteName = ['de', 'da', 'dos'];

  nameBreak.forEach((item, index) => {
    if (index <= 1) {
      _name = _name + ' ' + item;
    }

    if (index === 2 && !deleteName.includes(item.toLocaleLowerCase())) {
      _name = _name + ' ' + item;
    }
  });

  return _name;
}

export const ScrollPage = (to: string, type: 'id' | 'classes' = 'id') => {
  const yOffset = -60;
  let tagElement: any = '';
  if (type === 'classes') {
    tagElement = document.querySelector('.' + to);
  }
  if (type === 'id') {
    tagElement = document.getElementById(to);
  }
  if (!tagElement) {
    return;
  }
  const y = tagElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
};

export const asyncLocalStorage = {
  setItem: async function (key: string, value: any) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: async function (key: string) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};

export const searchParams = () => {
  return new URLSearchParams(window.location.search);
};

export const cookies = {
  set: (name: string, value: string, days: number) => {
    let expires;

    if (days) {
      const date = new Date();

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

      expires = '; expires=' + date.toUTCString();
    } else {
      expires = '';
    }

    document.cookie = name + '=' + value + expires + '; path=/';
  },

  get: (c_name: string) => {
    return new Promise(resolve => {
      if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + '='),
          c_end;

        if (c_start !== -1) {
          c_start = c_start + c_name.length + 1;
          c_end = document.cookie.indexOf(';', c_start);

          if (c_end === -1) {
            c_end = document.cookie.length;
          }

          resolve(unescape(document.cookie.substring(c_start, c_end)));
        }
      }

      resolve(null);
    });
  },
};

export function randomKey(index?: number) {
  const _index = typeof index === 'number' ? index : Math.random() * 5;

  return +new Date() * Math.random() * (_index + 1);
}

export const getCourseStatus = (percent?: number): string => {
  let status = '';

  if (typeof percent === 'number') {
    status = percent <= 0 ? 'Novo' : percent > 0 && percent < 99.99 ? 'Em andamento' : 'Concluído';
  }

  return status;
};

export const getYouTubeID = (url: string): string | null => {
  const regex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

  if (!regex.test(url)) return null;

  const anatomyURL = url.match(regex);
  const code: string | null = !!Array.isArray(anatomyURL) ? anatomyURL[5] : null;

  if (!code) return null;

  return code;
};

export function formatBytes(bytes: number, decimals?: number): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const shuffleArray = (array: any[]) => {
  return [...array].sort(() => Math.random() - 0.5);
};

export const findProgressByID = (items: ProgressInterface[], findID: string): ProgressInterface | null => {
  const obj: ProgressInterface[] = [],
    recursiveFn = (items: ProgressInterface[]) => {
      for (const item of items) {
        if (item._id === findID) {
          obj.push(item);
        }

        if (item.items) {
          recursiveFn(item.items);
        }
      }
    };

  recursiveFn(items);

  if (obj.length) {
    return obj[0];
  } else {
    return null;
  }
};
