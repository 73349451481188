import './style.scss';

import Loading from 'assets/images/loading.svg';

export function Splash() {
  return (
    <div className="w-screen h-screen bg-white fixed top-0 left-0 z-50 flex justify-center items-center">
      <img loading="lazy" src={Loading} className="w-36" alt="Carregando" />
    </div>
  );
}
