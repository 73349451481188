/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ChildrenProp } from 'types';
import { useAuth } from 'contexts';
import { getToken } from 'utils/tokenCookies';

export interface WebsocketContextInterface {
  // sendMessage: (message: any) => void;
}

const WebsocketContext = createContext({} as WebsocketContextInterface);

function WebsocketProviderData(): WebsocketContextInterface {
  const { user } = useAuth();
  const [privateClient, setPrivateClient] = useState<any>();

  function disconnect() {
    if (privateClient) {
      privateClient.close();
    }

    setPrivateClient(undefined);
  }

  function connect() {
    const urlService =
      !!(window as any).FUNIFIER_ENV?.service && typeof (window as any).FUNIFIER_ENV?.service === 'string'
        ? (window as any).FUNIFIER_ENV?.service.replace(/https?/gi, 'wss')
        : !!process.env.REACT_APP_API_URL && typeof process.env.REACT_APP_API_URL === 'string'
        ? process.env.REACT_APP_API_URL.replace(/https?/gi, 'wss').replace(/\/v3$/gi, '')
        : null;

    if (!urlService) return null;

    try {
      if (privateClient) {
        privateClient.close();
      }

      const token = getToken();

      if (token) {
        const socketPrivateClient = new WebSocket(`${urlService}/ws/private?authorization=${encodeURIComponent(token)}`);

        setPrivateClient(socketPrivateClient);
      }
    } catch (e) {
      console.log(e);
      disconnect();
    }
  }

  function onMessagePrivate(e: MessageEvent) {
    dispatchEvent(new CustomEvent('websocket-private-message', { detail: JSON.parse(e.data) }));
  }

  useEffect(() => {
    if (user) {
      connect();
    } else {
      disconnect();
    }
  }, [user]);

  useEffect(() => {
    if (privateClient) {
      privateClient.onmessage = onMessagePrivate;
    }

    return () => {
      if (privateClient) {
        privateClient.onmessage = null;
      }
    };
  }, [privateClient]);

  return {};
}

const WebsocketProvider = ({ children }: ChildrenProp) => {
  const contextData: WebsocketContextInterface = WebsocketProviderData();
  return <WebsocketContext.Provider value={contextData}>{children}</WebsocketContext.Provider>;
};

const useWebsocket = (): WebsocketContextInterface => {
  const context = useContext(WebsocketContext);

  if (typeof context === 'undefined') {
    throw new Error('useWebsocket must be used within an WebsocketProvider');
  }

  return context;
};

export { WebsocketProvider, useWebsocket };
