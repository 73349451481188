import { IconContext } from 'react-icons';
import { ChildrenProp } from 'types';

const IconProvider = ({ children }: ChildrenProp) => {
  const defaultValues = { className: 'icon', style: { verticalAlign: 'middle' } };

  return (
    <>
      <IconContext.Provider value={defaultValues}>{children}</IconContext.Provider>
    </>
  );
};

export { IconProvider };
