import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAuth, useToast } from 'contexts';
import { sendMessage } from 'services';

import './style.scss';

type InputsForm = {
  type: string;
  subject: string;
  message: string;
};

export default function ContactPage() {
  const { user } = useAuth();
  const { toastify } = useToast();
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);

  const {
    register,
    handleSubmit: submit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputsForm>();

  const form_subject = watch('subject', '');
  const form_message = watch('message', '');

  const handleSubmit: SubmitHandler<InputsForm> = async data => {
    if (sending || !user) return;

    setSending(true);

    sendMessage(data.type.trim(), data.subject.trim(), data.message.trim())
      .then(data => {
        toastify({
          content: 'Mensagem enviada com sucesso!',
          type: 'success',
        });

        navigate('../');
      })
      .catch(() => {
        toastify({
          content: 'Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente.',
          type: 'error',
        });
      })
      .finally(() => setSending(false));
  };

  return (
    <section id="contact">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Contato</h2>

      <form action="" onSubmit={submit(handleSubmit)} noValidate>
        <ul>
          <li>
            <label htmlFor="type">Tipo da mensagem:</label>
            <select
              id="type"
              aria-invalid={errors.type ? 'true' : 'false'}
              {...register('type', {
                required: 'Campo obrigatório.',
              })}
              disabled={sending}
              tabIndex={1}
            >
              <option value="">Selecione</option>
              <option value="Dúvida">Dúvida</option>
              <option value="Sugestão">Sugestão</option>
              <option value="Elogio">Elogio</option>
              <option value="Reclamação">Reclamação</option>
            </select>
            {errors.type && <span role="alert">{errors.type.message}</span>}
          </li>
          <li>
            <label htmlFor="subject">Assunto da mensagem:</label>
            <span className="block relative">
              <input
                tabIndex={2}
                type="text"
                id="subject"
                aria-invalid={errors.subject ? 'true' : 'false'}
                {...register('subject', {
                  required: 'Campo obrigatório.',
                  maxLength: {
                    value: 60,
                    message: 'Limite de 60 caracteres excedido.',
                  },
                })}
                disabled={sending}
              />
              <span className="count">{form_subject?.trim().length || 0}/60</span>
            </span>
            {errors.subject && <span role="alert">{errors.subject.message}</span>}
          </li>
          <li>
            <label htmlFor="message">Mensagem:</label>
            <span className="block relative">
              <textarea
                tabIndex={3}
                id="message"
                aria-invalid={errors.message ? 'true' : 'false'}
                {...register('message', {
                  required: 'Campo obrigatório.',
                  maxLength: {
                    value: 1000,
                    message: 'Limite de 1000 caracteres excedido.',
                  },
                })}
                disabled={sending}
              ></textarea>
              <span className="count">{form_message?.trim().length || 0}/1000</span>
            </span>
            {errors.message && <span role="alert">{errors.message.message}</span>}
          </li>
        </ul>

        <button type="submit" disabled={sending} tabIndex={4}>
          Enviar
        </button>
      </form>
    </section>
  );
}
