import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Achievement, Modal } from 'components';
import { ChildrenProp } from 'types';
import { useAuth } from 'contexts';

interface ModalContextInterface {
  showModal: (modalId: string, component: React.ReactNode, className?: string) => void;
  closeModal: () => void;
  modalOpened: boolean;
  modalData: ModalDataInterface | undefined;
  modalIsClosing: boolean;
}

export interface ModalDataInterface {
  component: React.ReactNode | undefined;
  id: string | undefined;
  className?: string;
}

const ModalContext = createContext({} as ModalContextInterface);

function ModalProviderData(): ModalContextInterface {
  const location = useLocation();

  const [modalData, setModalData] = useState<ModalDataInterface>();
  const [modalIsClosing, setModalIsClosing] = useState<boolean>(false);

  useEffect(() => {
    closeModal();
  }, [location]);

  function showModal(modalId: string, component: React.ReactNode, className?: string) {
    setModalData({
      id: modalId,
      component,
      className,
    });
  }

  function closeModal() {
    setModalIsClosing(true);

    setTimeout(() => {
      setModalData({
        component: undefined,
        id: undefined,
      });
      setModalIsClosing(false);
    }, 300);
  }

  const modalOpened = useMemo(() => {
    return typeof modalData?.id === 'string' && !!modalData?.id.length;
  }, [modalData?.id]);

  useEffect(() => {
    if (modalOpened) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [modalOpened]);

  const escFunction = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  }, []);

  useEffect(() => {
    const listener = async ({ data }: any) => {
      if (data.type === 'achievement') {
        setModalData({
          component: <Achievement value={data.value || ''} />,
          id: 'achievement',
        });
      }
    };

    document.addEventListener('keydown', escFunction, false);
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return {
    showModal,
    closeModal,
    modalOpened,
    modalData,
    modalIsClosing,
  };
}

const ModalProvider = ({ children }: ChildrenProp) => {
  const contextData: ModalContextInterface = ModalProviderData();
  return (
    <ModalContext.Provider value={contextData}>
      {children}
      {contextData.modalOpened && <Modal />}
    </ModalContext.Provider>
  );
};

const useModal = (): ModalContextInterface => {
  const context = useContext(ModalContext);

  if (typeof context === 'undefined') {
    throw new Error('useModal must be used within an ModalProvider');
  }

  return context;
};

export { ModalProvider, useModal };
