import { useContext, createContext, useState } from 'react';
import { toast, ToastContainer, ToastPosition } from 'react-toastify';

import { ChildrenProp } from 'types';

export interface ToastContextInterface {
  toastify: (params: toastifyParams) => void;
  setToastEnabled: (value: boolean) => void;
  toastEnabled: boolean;
}

interface toastifyParams {
  content: string;
  type?: 'info' | 'success' | 'warn' | 'error' | 'default';
  config?: toastifyConfig;
}

interface toastifyConfig {
  position?: ToastPosition;
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: any;
}

const ToastContext = createContext({} as ToastContextInterface);

function ToastProviderData(): ToastContextInterface {
  const [toastEnabled, setToastEnabled] = useState<boolean>(true);

  function toastify(params: toastifyParams) {
    if (!toastEnabled || !params || typeof params !== 'object' || !params.content) return;

    const defaultConfig: toastifyConfig = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };

    const config: toastifyConfig = {
      ...defaultConfig,
      ...(params && params.config && typeof params.config === 'object' && params.config),
    };

    if (typeof params.type === 'undefined' || params.type === 'default') {
      toast(params.content, config);
    } else {
      toast[params.type](params.content, config);
    }
  }

  return {
    toastify,
    setToastEnabled,
    toastEnabled,
  };
}

const ToastProvider = ({ children }: ChildrenProp) => {
  const contextData: ToastContextInterface = ToastProviderData();
  return (
    <>
      <ToastContext.Provider value={contextData}>
        {children}
        <ToastContainer />
      </ToastContext.Provider>
    </>
  );
};

const useToast = (): ToastContextInterface => {
  const context = useContext(ToastContext);

  if (typeof context === 'undefined') {
    throw new Error('useToast must be used within an ToastProvider');
  }

  return context;
};

export { ToastProvider, useToast };
