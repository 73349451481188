import React, { useImperativeHandle } from 'react';
import { useRef, useState, useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

type AvatarEditorPropsType = {
  onRenderStart?: () => void;
  onRenderFinished?: (url: string) => void;
};
const AvatarEditor = React.forwardRef((props: AvatarEditorPropsType, ref) => {
  const { onRenderStart, onRenderFinished } = props;

  const subdomain = 'inova3d'; // See section about becoming a partner
  const iFrameRef = useRef(null);
  // const [avatarUrl, setAvatarUrl] = useState('');
  const [showIFrame, setShowIFrame] = useState(false);

  function open() {
    setShowIFrame(true);
  }

  function close() {
    setShowIFrame(false);
  }

  function subscribe(event: any) {
    const json = parse(event);
    if (json?.source !== 'readyplayerme') {
      return;
    }
    // Subscribe to all events sent from Ready Player Me
    // once frame is ready
    if (json.eventName === 'v1.frame.ready') {
      const iFrame = iFrameRef.current as unknown as HTMLIFrameElement;
      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**',
          }),
          '*',
        );
      }
    }
    // Get avatar GLB URL
    if (json.eventName === 'v1.avatar.exported') {
      // console.log(`Avatar URL: ${json.data.url}`);
      // setAvatarUrl(json.data.url);
      renderAvatar(json.data.url);
      close();
    }
    // Get user id
    if (json.eventName === 'v1.user.set') {
      console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
    }
  }

  function parse(event: any) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  function renderAvatar(url: string) {
    if (!url) return;

    if (onRenderStart) {
      onRenderStart();
    }

    const params = {
      model: url,
      scene: 'fullbody-portrait-v1-transparent',
      armature: 'ArmatureTargetMale',
      blendShapes: {
        Wolf3D_Avatar: {
          browInnerUp: 0.3,
          mouthSmile: 0.4,
        },
      },
    };
    const http = new XMLHttpRequest();
    http.open('POST', 'https://render.readyplayer.me/render');
    http.setRequestHeader('Content-type', 'application/json');
    http.send(JSON.stringify(params));
    http.onload = function () {
      // Do whatever with response
      try {
        const { renders } = JSON.parse(http.responseText);
        console.log(renders);

        // document.getElementById("render").innerHTML = `<img src="${renders[0]}" alt="">`;
        // updateAvatarImage(renders[0]);

        if (onRenderFinished) {
          onRenderFinished(renders[0]);
        }
      } catch (err) {}
    };
  }

  // useEffect(() => {
  //   if (avatarUrl) {
  //     console.log(avatarUrl, 'upload da imagem no Funifier');
  //   }
  // }, [avatarUrl]);

  useEffect(() => {
    const iFrame = iFrameRef.current as unknown as HTMLIFrameElement;
    if (iFrame) {
      iFrame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
    }
  });

  useEffect(() => {
    window.addEventListener('message', subscribe);
    document.addEventListener('message', subscribe);
    return () => {
      window.removeEventListener('message', subscribe);
      document.removeEventListener('message', subscribe);
    };
  });

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  if (!showIFrame) {
    return null;
  }

  return (
    <div
      id="avatar-editor"
      className="fixed flex flex-col items-stretch top-0 left-0 z-[999] bg-blue-900 bg-opacity-90 w-full h-full p-10"
    >
      <div className="w-full flex flex-row pb-5 items-center justify-between">
        <h1 className="text-white text-xl lg:text-3xl font-bold">Personalize seu avatar</h1>
        <button onClick={() => close()} className="text-white text-sm lg:text-xl flex items-center space-x-3">
          <span>FECHAR</span>
          <AiOutlineCloseCircle size={24} />
        </button>
      </div>
      <div className="flex-1 w-full rounded-xl overflow-hidden">
        <iframe
          allow="camera *; microphone *"
          className="iFrame w-full h-full"
          id="frame"
          ref={iFrameRef}
          title={'Ready Player Me'}
        />
      </div>
    </div>
  );
});

export default AvatarEditor;
