import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import { AppProvider } from 'contexts';
import MyRoutes from './routes';

function App() {
  moment.locale('pt-br');

  return (
    <BrowserRouter>
      <AppProvider>
        <MyRoutes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
