import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { IoIosArrowUp } from 'react-icons/io';

import { NewsInterface } from 'types';
import { useBasic } from 'contexts';

import noImage from 'assets/images/no-image.jpg';

type ItemNewsProps = {
  item: NewsInterface;
  markRead: (value: string) => void;
};

export default function ItemNews({ item, markRead }: ItemNewsProps) {
  const { newsIDs, loadingNewsIDs } = useBasic();

  const [open, setOpen] = useState(false);

  const isNew = useMemo(() => {
    return !newsIDs?.find(x => x._id === item._id)?.read;
  }, [item._id, newsIDs]);

  useEffect(() => {
    if (open && isNew && !loadingNewsIDs) {
      markRead(item._id);
    }
  }, [isNew, item._id, loadingNewsIDs, markRead, open]);

  return (
    <div className={`item-news ${open ? 'opened' : 'closed'} ${isNew ? 'new' : ''}`}>
      <button
        type="button"
        className={`btn flex items-center justify-between w-full`}
        onClick={() => setOpen(prevstate => !prevstate)}
      >
        <span className="ico rounded-full"></span>

        <span className="image flex items-center justify-center rounded-full overflow-hidden">
          {!!item.image?.medium?.url ? (
            <img src={item.image?.medium?.url} alt={item.title || ''} />
          ) : (
            <img src={noImage} alt=" " />
          )}
        </span>

        <div className="infos">
          <p className="text">{item.title}</p>
          <p className="date">
            {format(item.publish_date, "'Em' dd 'de' MMMM 'de' yyyy', às' HH:mm'h'", {
              locale: ptBR,
            })}
          </p>
        </div>

        <span className="arrow">
          <IoIosArrowUp />
        </span>
      </button>
      <div className="content">
        <div className="text" dangerouslySetInnerHTML={{ __html: item?.detail || '' }} />
      </div>
    </div>
  );
}
