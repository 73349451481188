import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Header, SideNav, Splash } from 'components';
import { useAuth, useBasic } from 'contexts';
import { ChildrenProp } from 'types';
import PATHS from 'routes/paths';

export default function Layout({ children }: ChildrenProp) {
  const location = useLocation();
  const { fullLoading, showHeader, customClass, showSideNav, sideNavOpened, setSideNavOpened } = useBasic();
  const { user } = useAuth();

  const verifyRoute = useCallback(() => {
    const routes = PATHS.filter(route => !route.onlyInRouter);
    const getNavActiveIndex = () =>
      routes.findIndex(
        route =>
          location.pathname === route.pathname ||
          (location.pathname.match(/recompensas/gi) && route.pathname.match(/recompensas/gi)) ||
          (location.pathname.match(/configuracoes/gi) && route.pathname.match(/configuracoes/gi)),
      );

    if (getNavActiveIndex() < 0) {
      setSideNavOpened(false);
    } else {
      setSideNavOpened(true);
    }
  }, [location.pathname, setSideNavOpened]);

  useEffect(() => {
    window.scroll(0, 0);
    verifyRoute();
  }, [location, verifyRoute]);

  return (
    <>
      <div
        className={`container ${typeof customClass === 'string' && customClass.trim().length ? `${customClass.trim()}` : ''} ${
          !user || !showSideNav ? 'no-aside' : ''
        } ${!sideNavOpened ? 'aside-min' : 'aside-full'}`}
      >
        {showSideNav && !!user && <SideNav />}

        <main className="flex flex-col">
          {showHeader && <Header />}
          {children}
        </main>

        {/* {showFooter && <Footer />} */}

        {/* <Cookies /> */}
      </div>

      {!!fullLoading && <Splash />}
    </>
  );
}
