/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { RiCoinsLine } from 'react-icons/ri';
import { FaMedal } from 'react-icons/fa';
import { BsCoin } from 'react-icons/bs';
import ReactModal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
// import { useNavigate, useParams } from 'react-router';

import { Placeholder } from 'components';
import Button from 'components/ui/button';
import { useAuth, useBasic } from 'contexts';

import { amountItemsRemaining, getVirtualGood, getVirtualGoods, ItemsRemaining, purchaseItem } from 'services';

export default function ShopDetail() {
  const { pointsCategories, userPoints } = useBasic();
  const [virtualGoods, setVirtualGoods] = useState<any>();
  const [virtualGood, setVirtualGood] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [purchased, setPurchased] = useState(false);
  const [itemsRemaining, setItemsRemaining] = useState<ItemsRemaining[]>();

  const { user, refreshUser } = useAuth();

  const params = useParams();
  const navigate = useNavigate();

  // const [isOpen, setIsOpen] = useState(true);

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    if (processing) return;

    navigate('../');
  }

  function afterOpenModal() {}

  useEffect(() => {
    if (!params.id || !user) {
      return;
    }

    const loadData = async () => {
      try {
        await getVirtualGoods()
          .then((items: any[]) => {
            setVirtualGoods(items);
          })
          .catch(error => {
            console.error(error);
          });

        const good = await getVirtualGood(params.id as string);

        await amountItemsRemaining()
          .then((items: ItemsRemaining[]) => {
            setItemsRemaining(items);
          })
          .catch(error => {
            console.error(error);
          });

        // console.log(good)
        setVirtualGood(good);
        setPurchased(isPurchased(good));
      } catch (err) {}
      setLoading(false);
    };

    loadData();
  }, [params.id, user]);

  function isPurchased(good?: any) {
    if (!user?.catalog_items) return false;
    try {
      const purchased = Object.keys(user?.catalog_items).find((item: any) => {
        // console.log('isPurchased', item, good._id);
        return item === good._id;
      });
      return !!purchased;
    } catch (e) {
      return false;
    }
  }

  function verifyRequirements() {
    const elem = itemsRemaining?.find((x: ItemsRemaining) => x._id === virtualGood._id);
    const restrictions = virtualGoods?.find((x: { _id: any }) => x._id === virtualGood._id).restrictions;
    let hasQuantity = true;

    if (!!elem && elem.total_disponivel === 0) {
      hasQuantity = false;
    }

    return (!restrictions || (!!restrictions && !restrictions.length)) && hasQuantity;
  }

  async function handleRedeem(item: any) {
    if (!user) return;

    try {
      if (item.extra.url) {
        window.open(item.extra.url, '_blank');
      } else {
        setProcessing(true);
        setPurchased(false);
        const purchaseResult = await purchaseItem(item._id, user._id);
        if (purchaseResult && purchaseResult.achievements.length) {
          await refreshUser();
          setPurchased(true);
        }
        setProcessing(false);
      }
    } catch (err) {
      setProcessing(false);
    }
  }

  function renderLoading() {
    return (
      <div className="text-center p-4 w-full">
        <Placeholder />
      </div>
    );
  }

  function invalidItem() {
    return <div className="text-center p-4">Item inválido</div>;
  }

  const getItemRequires = (require: any) => {
    const pointObj = pointsCategories?.find(item => item._id === require.item);

    return (
      <p
        key={`${require.item}_${require.total}`}
        className="w-full flex items-center justify-center my-1"
        title={pointObj?.category}
      >
        <strong className="mr-1 not-italic font-bold text-lg lg:text-2xl leading-none text-purple">{require.total}</strong>
        {!!pointObj?.image ? (
          <>
            <img src={pointObj.image} alt={pointObj.category} className="w-6 inline-block" />
          </>
        ) : (
          <>
            {!!pointObj?._id?.match(/xp/gi) ? (
              <FaMedal className="text-lg lg:text-2xl text-purple inline-block" />
            ) : (
              <BsCoin className="text-lg lg:text-2xl text-purple inline-block" />
            )}
          </>
        )}
      </p>
    );
  };

  function renderItem() {
    if (!virtualGood) {
      return null;
    }

    return (
      <div className="flex flex-col w-full justify-center items-center space-y-4">
        <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-5">
          {virtualGood.image && (
            <div className="w-36 lg:w-64 bg-gray-100 rounded-xl overflow-hidden aspect-square">
              <img src={virtualGood.image.original.url} alt={virtualGood.name} className="w-full h-full object-contain" />
            </div>
          )}
          <div className="mt-3 lg:mt-0 space-y-3">
            <div className="text-xl lg:text-3xl text-center lg:text-left font-bold">{virtualGood.name}</div>
            {!!virtualGood.description && <div className="w-full text-center border border-solid">{virtualGood.description}</div>}

            {virtualGood.requires.length > 0 && (
              <div
                className={`flex flex-col items-center bg-white rounded-xl shadow-lg text-orange-500 text-center font-bold text-lg lg:text-2xl py-1 px-3 ${
                  !verifyRequirements() ? 'text-red-500' : ''
                }`}
              >
                {virtualGood.requires.map((require: any) => getItemRequires(require))}
              </div>
            )}
          </div>
        </div>

        {processing && (
          <div className="p-3 lg:p-4 flex items-center">
            <Button color="outline" className="text-base lg:text-xl">
              Processando troca...
            </Button>
          </div>
        )}

        {!processing && !purchased && verifyRequirements() && (
          <div className="p-3 lg:p-4 flex items-center">
            <Button color="success" className="text-base lg:text-xl" onClick={() => handleRedeem(virtualGood)}>
              Trocar
            </Button>
          </div>
        )}

        {!processing && purchased && (
          <div className="p-3 lg:p-4 flex items-center">
            <Button color="outline" className="text-base lg:text-xl pointer-events-none">
              Item trocado
            </Button>
          </div>
        )}

        {!processing && !verifyRequirements() && (
          <div className="flex items-center">
            <Button disabled color="danger" className="text-base lg:text-xl">
              Você não possui pontuação suficiente
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <ReactModal
      key={params.id}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      isOpen={true}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Example Modal"
      portalClassName=""
      overlayClassName="fixed z-50 top-0 left-0 w-full h-full w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
      contentElement={() => (
        <div
          className={`bg-purple backdrop-blur-md bg-opacity-95 md:bg-opacity-80 rounded-lg p-3 shadow-lg md:bg-opacity-90 p-3 lg:p-5 flex flex-col justify-center items-center lg:w-[500px] space-y-4`}
        >
          <div className="flex w-full flex-row items-center justify-between">
            <h1 className="text-xl lg:text-2xl font-bold">PlayDPS Shop</h1>
            <Button onClick={() => handleCloseModal()}>Fechar</Button>
          </div>

          <div className={`flex w-full p-3 rounded-xl flex-row items-center justify-between leading-none bg-purple-light`}>
            <b>Sua pontuação:</b>
            <div className="flex flex-col items-center text-base lg:text-lg text-white-500 pt-1">
              {userPoints?.map(point => (
                <p
                  key={`${point._id}_${point.total}`}
                  className="w-full flex items-center justify-end mb-1"
                  title={point.category}
                >
                  <strong className="mr-1 not-italic font-bold text-lg lg:text-xl leading-none text-white-500">
                    {point.total}
                  </strong>
                  {!!point?.image ? (
                    <>
                      <img src={point.image} alt={point.category} className="w-6 inline-block" />
                    </>
                  ) : (
                    <>
                      {!!point?._id?.match(/xp/gi) ? (
                        <FaMedal className="text-lg lg:text-xl text-white-500 inline-block" />
                      ) : (
                        <BsCoin className="text-lg lg:text-xl text-white-500 inline-block" />
                      )}
                    </>
                  )}
                </p>
              ))}
            </div>
          </div>

          {loading && renderLoading()}
          {!virtualGood && !loading && invalidItem()}
          {!!virtualGood && !loading && renderItem()}
        </div>
      )}
    />
  );
}
