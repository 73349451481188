import { useMemo } from 'react';
import Lottie from 'lottie-react';

import { useModal } from 'contexts';

import './style.scss';

import badgeAnimation from 'assets/lottie/badge.json';

type AchievementProps = {
  value?: any;
  className?: string;
  [key: string]: any;
};

export function Achievement({ value, className, ...rest }: AchievementProps) {
  const { closeModal } = useModal();

  const typeName = useMemo(() => {
    if (!value?.type) return '';

    switch (value.type) {
      case 'challenge':
        return 'completou um desafio';
      case 'mission':
        return 'completou uma missão';
      case 'badge':
        return 'conquistou uma Badge';
      case 'point':
        return `ganhou ${Math.round(value?.total || 0)} ${value?.title}`;
      default:
        return '';
    }
  }, [value]);

  return (
    <div className={`achievement-component ${className || ''}`}>
      <h2 className="title">Parabéns!</h2>

      {!!typeName.length && (
        <h3 className="subtitle">
          Você {typeName}
          {value?.title && value.type !== 'point' ? ':' : '.'}{' '}
          {!!value?.title && value.type !== 'point' && <span>{value?.title}</span>}
        </h3>
      )}
      {!!value?.description?.length && <p className="description">{value?.description}</p>}

      <div className="badge">
        <Lottie animationData={badgeAnimation} loop={true} />
      </div>

      <div className="actions">
        <button onClick={() => closeModal()}>Fechar</button>
      </div>
    </div>
  );
}
