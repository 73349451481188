import { useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';

import { FaqInterface } from 'types';

type ItemFaqProps = {
  item: FaqInterface;
};

export default function ItemFaq({ item }: ItemFaqProps) {
  const [open, setOpen] = useState(false);

  return (
    <div className={`item-faq ${open ? 'opened' : 'closed'}`}>
      <button type="button" onClick={() => setOpen(prevstate => !prevstate)}>
        <span className="question">{item.question}</span>
        <span className="arrow">
          <IoIosArrowUp />
        </span>
      </button>
      <div className="content">
        <div className="text" dangerouslySetInnerHTML={{ __html: item?.answer || '' }} />
      </div>
    </div>
  );
}
