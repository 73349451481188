import { useMemo } from 'react';

import { useBasic, useCourses } from 'contexts';
import { BoxCourses, BoxNextLessons } from 'components';
import { Placeholder } from 'components/Placeholder';

import './style.scss';

export default function HomePage() {
  const { width } = useBasic();
  const { listCourses, nextLessons } = useCourses();

  const titleNextLessons = useMemo(() => {
    if (!nextLessons) return '';

    if (nextLessons.length > 1) {
      if (width > 900) {
        return 'Próximos conteúdos';
      } else {
        return 'Próximo conteúdo';
      }
    } else if (nextLessons.length === 1) {
      return 'Próximo conteúdo';
    } else {
      return 'Sua jornada começa aqui!';
    }
  }, [nextLessons, width]);

  if (!Array.isArray(listCourses) || !Array.isArray(nextLessons)) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!listCourses.length) {
    return (
      <>
        <h2 className="block text-center p-6">Nenhum curso encontrado.</h2>
      </>
    );
  }

  return (
    <>
      <BoxNextLessons
        className="pb-6 lg:pb-0 border-b lg:border-b-0 mb-6 lg:mb-12"
        lessons={nextLessons}
        title={titleNextLessons}
        maxLength={width >= 1366 ? 2 : 1}
      />
      <BoxCourses courses={listCourses} title="Seus Cursos" maxLength={width > 1440 ? 3 : 2} />
    </>
  );
}
