import { Link } from 'react-router-dom';

import { NextLessonsInterface } from 'types';

import './style.scss';

import noImage from 'assets/images/no-image.jpg';

export type CardLessonProps = {
  lesson: NextLessonsInterface;
  style?: React.CSSProperties;
  className?: string;
  [key: string]: any;
};

export function CardLesson({ lesson, style, className, ...rest }: CardLessonProps) {
  const getBreadcrumb = () => {
    if (lesson.breadcrumbs.length > 1) {
      const items = lesson.breadcrumbs.slice(1, lesson.breadcrumbs.length);

      return (
        <p className="text-white text-sm leading-none font-normal">
          {items.map((item, index) => (
            <span key={index}>
              {index > 0 && <> - </>}
              {index + 1 === items.length && <strong className="text-white uppercase">{item.title}</strong>}
              {index + 1 < items.length && item.title}
            </span>
          ))}
        </p>
      );
    } else {
      return <></>;
    }
  };

  if (!Array.isArray(lesson.breadcrumbs)) return null;

  return (
    <div className={`card-lesson ${className || ''}`} style={style} {...rest}>
      <Link
        to={`/cursos/${lesson.breadcrumbs[0]._id}/${lesson._id}/${lesson.data._id}`}
        className="overflow-hidden relative block lg:flex justify-center items-center"
      >
        <img
          src={
            lesson.data?.data?.image?.length
              ? lesson.data?.data?.image
              : lesson.data?.extra?.image?.length
              ? lesson.data?.extra?.image
              : noImage
          }
          alt={lesson.data?.title || lesson.title}
          className="w-full block object-cover transition-transform duration-300 ease-in-out"
        />
        <div className="card-lesson__metadata flex justify-between items-start lg:items-center absolute z-10">
          <div className="card-lesson__crumbs">
            {!!lesson.breadcrumbs[0]?.title && (
              <p className="text-white text-sm lg:text-base leading-none font-semibold mb-1">
                <strong>{lesson.breadcrumbs[0]?.title}</strong>
              </p>
            )}
            {getBreadcrumb()}
          </div>
          {(!!lesson.data?.data?.duration || !!lesson.data?.extra?.duration) && (
            <p className="card-lesson__time text-white text-sm backdrop-filter backdrop-blur leading-none rounded-lg font-semibold">
              {lesson.data?.data?.duration || lesson.data?.extra?.duration}
            </p>
          )}
        </div>
        {(!!lesson.data?.title || !!lesson.data?.data?.intro || !!lesson.data?.extra?.intro) && (
          <div className="card-lesson__title lg:backdrop-filter lg:backdrop-blur-lg lg:absolute z-10 lg:rounded-3xl block lg:flex justify-center flex-col lg:bg-black lg:bg-opacity-30">
            {!!lesson.data?.title && (
              <h3 className="lg:text-white uppercase text-base lg:text-lg leading-none font-bold mb-1">{lesson.data?.title}</h3>
            )}
            {(!!lesson.data?.data?.intro || !!lesson.data?.extra?.intro) && (
              <p className="lg:text-white text-sm lg:text-lg leading-none font-normal">
                {lesson.data?.data?.intro || lesson.data?.extra?.intro}
              </p>
            )}
          </div>
        )}
      </Link>
    </div>
  );
}
