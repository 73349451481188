import { FaMedal } from 'react-icons/fa';

import { Placeholder } from 'components';
import { useAuth, useBasic } from 'contexts';

import './style.scss';
import { BsCoin } from 'react-icons/bs';

type UserStatsProps = {
  className?: string;
  [key: string]: any;
};

export function UserStats({ className, ...rest }: UserStatsProps) {
  const { user } = useAuth();
  const { userPoints, pointsCategories } = useBasic();

  function loader() {
    return (
      <div className="w-full grid grid-cols-2 h-48 gap-4">
        <div className="grid h-48 gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
        </div>
        <div className="grid h-48 gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
        </div>
      </div>
    );
  }

  function render() {
    return (
      <>
        {!!userPoints && (
          <div className="points w-full block">
            <h3 className="title text-lg mb-2 w-full block font-medium">Pontos</h3>
            {userPoints.map(point => (
              <div key={`${point._id}_${point.total}`} className="item flex items-center justify-start">
                <span className="img flex items-center justify-center">
                  {!!point.image ? (
                    <>
                      <img src={point.image} alt={point.category} className="block w-full h-full" />
                    </>
                  ) : (
                    <>
                      {!!point._id?.match(/xp/gi) ? (
                        <FaMedal className="block w-full h-full" />
                      ) : (
                        <BsCoin className="block w-full h-full" />
                      )}
                    </>
                  )}
                </span>

                <p className="">
                  <strong className="">{point.category}</strong>
                  {point.total}
                </p>
              </div>
            ))}
          </div>
        )}

        {user?.level?.level ? (
          <div className="levels w-full block border-t lg:border-t-0 lg:border-l border-gray-100 border-solid">
            <h3 className="title text-lg mb-2 w-full block font-medium">Níveis</h3>

            <div className="item">
              <p className="actual">
                <strong>Nível atual</strong>
                {user.level.level}
              </p>
            </div>

            {user?.level_progress?.next_level?.level ? (
              <div className="item">
                <p>
                  <strong>Próximo nível</strong>
                  <span className="progress flex items-center justify-between w-full">
                    <span className="actual">{user.level.level}</span>
                    <span className="bar w-full block h-5 bg-gray-200 rounded">
                      <span
                        className="block h-5 rounded animate-bar-stripes bg-bar-stripes bg-size-bar-stripes"
                        style={{ width: `${user.level_progress.percent}%` }}
                      >
                        {Math.round(user.level_progress.percent)}%
                      </span>
                    </span>
                    <span className="next">{user.level_progress.next_level.level}</span>
                  </span>
                </p>
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }

  if (!user || !pointsCategories) {
    return <div className={`user-stats ${className || ''}`}>{loader()}</div>;
  }

  if (!userPoints && !user?.level?.level) {
    return <></>;
  }

  return <div className={`user-stats ${className || ''}`}>{render()}</div>;
}
