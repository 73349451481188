import { AiOutlineCloseCircle } from 'react-icons/ai';

import { useModal } from 'contexts';

import './style.scss';

export function Modal() {
  const { closeModal, modalOpened, modalData, modalIsClosing } = useModal();

  if (!modalOpened) {
    return null;
  }

  return (
    <div
      id="modal"
      className={`fixed flex items-center justify-center top-0 left-0 z-90 w-full h-full ${modalIsClosing ? 'fadeOut' : ''} ${
        modalData?.id
      }`}
    >
      <span className="fixed top-0 left-0 z-10 bg-black bg-opacity-70 w-full h-full" onClick={() => closeModal()}></span>
      <button className="bt-close absolute z-30" onClick={() => closeModal()} title="Fechar">
        <AiOutlineCloseCircle />
      </button>
      <div className={`modal-container relative z-20`}>
        <div className={`modal-content rounded-xl ${modalData?.className || ''}`}>{modalData?.component}</div>
      </div>
    </div>
  );
}
