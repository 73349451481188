import { api } from './api';

export interface PointsCategoriesInterface {
  _id: string;
  category: string;
  extra?: any;
  image: string;
  shortName?: string;
  techniques: string[];
}

export async function getPointsCategories(): Promise<PointsCategoriesInterface[]> {
  const { data } = await api.get(`database/point_category`);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.map(item => ({ ...item, image: item.image?.medium.url }));
  }
}
