import { ProgressInterface } from 'types';
import Row from './Row';

type TreeProps = {
  items: ProgressInterface[];
  parent: string;
  courseID: string;
};

export default function Tree({ items, parent, courseID }: TreeProps) {
  return (
    <>
      {items?.map((item: any) => (
        <Row key={item._id} item={item} parent={parent} courseID={courseID} />
      ))}
    </>
  );
}
