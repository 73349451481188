import { api } from './api';

export interface ItemsRemaining {
  _id: string;
  total_comprado: number;
  total_disponivel: number;
}

export async function getVirtualGoods() {
  try {
    const { data } = await api.get('virtualgoods/item?player=me&orderby=extra.order&reverse=false');
    return data;
  } catch (error) {
    return error;
  }
}

export async function getVirtualGood(_id: string) {
  try {
    const { data } = await api.get(`virtualgoods/item/${_id}`);
    return data;
  } catch (error) {
    return error;
  }
}

export async function purchaseItem(item: string, player: string) {
  try {
    const { data } = await api.post('virtualgoods/purchase', {
      item,
      player,
    });
    return data;
  } catch (error) {
    return error;
  }
}

export function amountItemsRemaining(): Promise<ItemsRemaining[]> {
  return new Promise<any>((resolve, reject) => {
    api
      .post('/database/achievement/aggregate?strict=true', [
        { $match: { type: 2 } },
        { $group: { _id: '$item', total_comprado: { $sum: '$total' } } },
        {
          $lookup: {
            from: 'catalog_item',
            localField: '_id',
            foreignField: '_id',
            as: 'i',
          },
        },
        { $unwind: '$i' },
        {
          $project: {
            _id: 1,
            total_comprado: 1,
            total_disponivel: {
              $cond: { if: { $gte: ['$i.amount', 0] }, then: { $subtract: ['$i.amount', '$total_comprado'] }, else: '$i.amount' },
            },
          },
        },
      ])
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
