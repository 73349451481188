import { useState, useEffect, useCallback, useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai';
import { HiArrowLeft } from 'react-icons/hi';
import { AiFillFileExcel, AiFillFileImage, AiFillFilePdf, AiFillFileWord, AiFillFileZip } from 'react-icons/ai';
import { FaFileAudio, FaFileCsv, FaFileDownload, FaFilePowerpoint, FaFileVideo } from 'react-icons/fa';

import { useAuth, useBasic, useCourses } from 'contexts';
import { actionLog, getBreadcrumb, getDetailContent, getLessonLikes, registerFolderLog } from 'services';
import { BreadcrumbInterface, DetailContentInterface, LessonLikesInterface, LessonLikesPlayerObj, File } from 'types';
import { Placeholder, Video, Text, Quiz } from 'components';
import { NextLessons } from './NextLessons';
import { findProgressByID, formatBytes } from 'utils';

import './style.scss';
import { GameContainer } from 'components/GameContainer';

export default function LessonPage() {
  const { user } = useAuth();
  const { setShowHeader } = useBasic();
  const { allProgress, getFullProgress } = useCourses();
  const { courseID, folderID, contentID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [lessonContent, setLessonContent] = useState<DetailContentInterface[]>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInterface[]>();
  const [loadingLikes, setLoadingLikes] = useState(true);
  const [likes, setLikes] = useState<LessonLikesInterface | null | undefined>();

  const getFolderPercentages = useMemo(() => {
    if (Array.isArray(allProgress) && allProgress.length && !!folderID) {
      const folderPercentage = findProgressByID(allProgress, folderID);

      if (!!folderPercentage) {
        return folderPercentage;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [allProgress, folderID]);

  const lesson = useMemo(() => {
    if (lessonContent?.length) {
      const content = lessonContent.find(x => x._id === contentID);

      if (content) {
        return content;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [contentID, lessonContent]);

  const getLikes = useCallback(async () => {
    setLoadingLikes(true);
    const _likes = await getLessonLikes(user?._id, contentID);
    setLikes(_likes);
    setLoadingLikes(false);
  }, [contentID, user]);

  useEffect(() => {
    if (folderID && contentID && !lessonContent) {
      setLoading(true);

      getDetailContent(folderID, false)
        .then(async data => {
          if (data?.length) {
            const breadcrumbs = await getBreadcrumb(folderID);

            if (breadcrumbs?.length) {
              setBreadcrumbs(breadcrumbs);
            }

            setLessonContent(data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [contentID, folderID, lessonContent]);

  useEffect(() => {
    getLikes();

    if (!!lesson?.type.match(/(quiz|game)/gi)) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    return () => setShowHeader(true);
  }, [getLikes, lesson, setShowHeader]);

  const handleLike: (value: string) => void = (actionID: string) => {
    if (loadingLikes) return null;

    setLoadingLikes(true);

    setLikes(prevstate => {
      if (typeof prevstate === 'object') {
        if (actionID === 'like_content') {
          return {
            ...(prevstate as LessonLikesInterface),
            playerLiked: 1,
            playerDisliked: false,
          };
        } else {
          return {
            ...(prevstate as LessonLikesInterface),
            playerLiked: false,
            playerDisliked: 1,
          };
        }
      }

      return prevstate;
    });

    actionLog(
      actionID,
      {
        contentId: contentID,
      },
      (likes?.playerLogObj as LessonLikesPlayerObj)?._id,
    )
      .then(() => {
        getLikes();
      })
      .finally(() => setLoadingLikes(false));
  };

  const registerLog = (contentId: string) => {
    registerFolderLog(user?._id, contentId, 'done').finally(() => {
      getFullProgress();
    });
  };

  const nextLessons: DetailContentInterface[] = useMemo((): DetailContentInterface[] => {
    if (!!lessonContent?.length && !!lesson && !!getFolderPercentages) {
      const obj: DetailContentInterface[] = [];

      lessonContent
        .sort((a, b) => a.position - b.position)
        .forEach((item, index) => {
          const lessonActive = item._id === lesson._id;
          const itemPercent = getFolderPercentages.items?.find(x => x._id === item._id);
          let lessonOpen: boolean;

          if (!!itemPercent && itemPercent.percent > 0) {
            lessonOpen = true;
          } else if (lessonActive) {
            lessonOpen = true;
          } else if (
            typeof getFolderPercentages.items !== 'undefined' &&
            getFolderPercentages.items[index - 1]?.percent === 100
          ) {
            lessonOpen = true;
          } else {
            lessonOpen = false;
          }

          obj.push({
            ...item,
            open: true, //lessonOpen,
            active: lessonActive,
          });
        });

      return obj;
    } else {
      return [];
    }
  }, [getFolderPercentages, lesson, lessonContent]);

  const mountBreadcrumb = () => {
    if (breadcrumbs && breadcrumbs.length > 1) {
      const items = breadcrumbs.slice(1, breadcrumbs.length);

      return (
        <>
          {' '}
          •{' '}
          {items.map((item, index) => (
            <span key={index}>
              {index > 0 && <> • </>}
              {index + 1 < items.length && item.title}
              {index + 1 === items.length && <strong>{item.title}</strong>}
            </span>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const getDocIcon = (extension: string) => {
    if (!!extension.match(/pdf/gi)) {
      return <AiFillFilePdf />;
    } else if (!!extension.match(/xls/gi)) {
      return <AiFillFileExcel />;
    } else if (!!extension.match(/csv/gi)) {
      return <FaFileCsv />;
    } else if (!!extension.match(/(zip|rar|tar|gz)/gi)) {
      return <AiFillFileZip />;
    } else if (!!extension.match(/doc/gi)) {
      return <AiFillFileWord />;
    } else if (!!extension.match(/ppt/gi)) {
      return <FaFilePowerpoint />;
    } else if (!!extension.match(/(png|jpg|jpeg|gif|bmp|webp)/gi)) {
      return <AiFillFileImage />;
    } else if (!!extension.match(/(mkv|mp4|avi|webm|wmv|mov)/gi)) {
      return <FaFileVideo />;
    } else if (!!extension.match(/(mp3|wav|wma|ogg|aac|flac)/gi)) {
      return <FaFileAudio />;
    } else {
      return <FaFileDownload />;
    }
  };

  if (!contentID && Array.isArray(allProgress) && allProgress.length) {
    if (getFolderPercentages?.items?.length) {
      return <Navigate to={`/cursos/${courseID}/${folderID}/${getFolderPercentages.items[0]._id}`} replace />;
    } else {
      return <Navigate to={`/erro-404`} replace />;
    }
  }

  if (loading) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!lesson || !breadcrumbs?.length) {
    return <Navigate to={`/erro-404`} replace />;
  }

  return (
    <>
      <section className="lesson flex-1">
        <div className={`content ${!nextLessons?.length || !!lesson.type.match(/(quiz|game)/gi) ? 'w-full flex flex-col' : ''}`}>
          <div className="head">
            {!!lesson.type.match(/(quiz|game)/gi) && (
              <button className="back" onClick={() => navigate(-1)}>
                <span className="ico">
                  <HiArrowLeft />
                </span>
                Voltar
              </button>
            )}

            <div className={`crumbs ${!!lesson.type.match(/(quiz|game)/gi) ? 'has-btn-back text-right' : ''}`}>
              {!!breadcrumbs[0].title && (
                <p>
                  <strong>{breadcrumbs[0].title}</strong>
                  {mountBreadcrumb()}
                  {!!lesson.type.match(/(quiz|game)/gi) && !!lesson.title && <span className="title">{lesson.title}</span>}
                </p>
              )}
            </div>
          </div>

          {!!lesson.data?.intro && !!lesson.type.match(/(video|doc)/gi) && <p className="intro">{lesson.data.intro}</p>}

          {lesson.type === 'video' && <Video content={lesson} />}

          {!lesson.type.match(/(quiz|game)/gi) && (
            <div className="content-data">
              <h2 className="title">{lesson.title}</h2>

              <div className="social">
                <button
                  className={`like ${!!likes?.playerLiked ? 'active' : ''}`}
                  onClick={() => (!likes?.playerLiked ? handleLike('like_content') : null)}
                  disabled={!!likes?.playerLiked}
                  title="Gostei"
                >
                  <AiOutlineLike />

                  {!!likes?.qtdLikes && <span>{likes.qtdLikes}</span>}
                </button>

                <button
                  className={`dislike ${!!likes?.playerDisliked ? 'active' : ''}`}
                  onClick={() => (!likes?.playerDisliked ? handleLike('dislike_content') : null)}
                  disabled={!!likes?.playerDisliked}
                  title="Não gostei"
                >
                  <AiOutlineDislike />
                </button>
              </div>
            </div>
          )}

          {lesson.type === 'text' && <Text content={lesson} />}

          {lesson.type === 'quiz' && <Quiz content={lesson} />}

          {lesson.type.match(/game_*/gi) && <GameContainer id={lesson.type} contentId={contentID} className="mb-5" />}

          {lesson.type === 'doc' && (
            <div className="download-file">
              <a
                href={(lesson.data.url as File).url}
                target="_blank"
                rel="noopener noreferrer"
                className="doc"
                download
                onClick={() => registerLog(lesson._id)}
              >
                <span className="ico">{getDocIcon((lesson.data.url as File).extension)}</span>
                <div>
                  <p className="title">Baixar arquivo</p>
                  <p className="meta">
                    {(lesson.data.url as File).extension?.replace(/\./gi, '').toUpperCase()} •{' '}
                    {formatBytes((lesson.data.url as File).size)}
                  </p>
                </div>
              </a>
            </div>
          )}

          {!!lesson.data?.description && lesson.type !== 'text' && (
            <p className="description" dangerouslySetInnerHTML={{ __html: lesson.data.description }} />
          )}
        </div>

        {!!nextLessons?.length && !!courseID && !!folderID && !lesson.type.match(/(quiz|game)/gi) && (
          <div className="right-data">
            {!!nextLessons?.length && <NextLessons nextLessons={nextLessons} courseID={courseID} folderID={folderID} />}

            {/* {!!lesson.content?.length && <ExtraContent content={lesson.content} />} */}
          </div>
        )}
      </section>
    </>
  );
}
