import { NewsIDsInterface, NewsInterface } from 'types';
import { api } from './api';

export async function getNewsIDs(player: string | undefined): Promise<NewsIDsInterface[] | null> {
  if (!player) return null;

  const { data } = await api.post('find/news_ids', { player });
  return Array.isArray(data) && data.length ? data : null;
}

export async function getNews(player: string | undefined): Promise<NewsInterface[] | null> {
  if (!player) return null;

  const { data } = await api.post('find/news_full', { player });
  return Array.isArray(data) && data.length ? data : null;
}

export async function putReadNews(userId: string | undefined, itemId: string) {
  if (!userId?.length || !itemId?.length) return null;

  const payload: any = {
    created_at: +new Date(),
    type: 'news',
    userId,
    itemId,
  };

  const { data } = await api.put('database/read_content__c', payload);
  return data;
}
