import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillFileText, AiFillVideoCamera } from 'react-icons/ai';
import { FaFileDownload } from 'react-icons/fa';
import { MdQuiz } from 'react-icons/md';
import { IoGameController, IoReturnDownForward } from 'react-icons/io5';
import { RiFolderFill, RiFolderLockFill, RiFolderOpenFill } from 'react-icons/ri';
import { BsArrowRight } from 'react-icons/bs';

import { ProgressInterface } from 'types';
import Tree from './Tree';

type RowProps = {
  item: ProgressInterface;
  parent: string;
  courseID: string;
};

export default function Row({ item, parent, courseID }: RowProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const isFolder = useMemo(() => !!item.items?.length && !!item.folder, [item]);

  const entityIcon = useMemo(() => {
    if (!!item.folder) {
      if (!!item.items?.length) {
        return isCollapsed ? <RiFolderFill /> : <RiFolderOpenFill />;
      } else {
        return <RiFolderLockFill />;
      }
    } else if (!item.folder) {
      if (item.type === 'video') {
        return <AiFillVideoCamera />;
      } else if (item.type === 'text') {
        return <AiFillFileText />;
      } else if (item.type === 'doc') {
        return <FaFileDownload />;
      } else if (item.type === 'quiz') {
        return <MdQuiz />;
      } else if (!!item.type.match(/game_/gi)) {
        return <IoGameController />;
      } else {
        return <></>;
      }
    }
  }, [isCollapsed, item]);

  const metadataContent = () => {
    return (
      <>
        <span className="subfolder">
          {!!item.folder && <IoReturnDownForward />}
          {!item.folder && <BsArrowRight />}
        </span>
        <span className="img">{entityIcon}</span>
        <span className="title">
          {item.title}
          {!item.folder ? (
            <strong className="type">
              {item.type === 'video' && 'Vídeo'}
              {item.type === 'text' && 'Texto'}
              {item.type === 'doc' && 'Documento'}
              {item.type === 'quiz' && 'Quiz'}
              {!!item.type.match(/game_/gi) && 'Minigame'} •{' '}
              <span className={`${item.percent === 100 ? 'concluded' : ''}`}>{`${Math.round(item.percent)}% concluído`}</span>
            </strong>
          ) : (
            ''
          )}
        </span>
      </>
    );
  };

  return (
    <div key={`section-${item._id}`} className={`row ${!isCollapsed ? 'active' : ''}`}>
      <div
        className={`metadata ${isFolder ? 'cursor-pointer' : ''} ${
          item.percent === 0 ? 'new' : item.percent === 100 ? 'concluded' : 'in-progress'
        } ${!!item.folder && !item.items?.length ? 'locked' : ''}`}
        onClick={() => (isFolder ? setIsCollapsed(!isCollapsed) : null)}
      >
        {!item.folder && <Link to={`/cursos/${courseID}/${parent}/${item._id}`}>{metadataContent()}</Link>}
        {!!item.folder && metadataContent()}
      </div>
      {isFolder && (
        <div className={`children ${!isCollapsed ? 'active' : ''}`}>
          <Tree items={item.items as ProgressInterface[]} parent={item._id} courseID={courseID} />
        </div>
      )}
    </div>
  );
}
