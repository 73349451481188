import { useState, useEffect, useMemo, useCallback } from 'react';

import { useAuth, useBasic } from 'contexts';
import { NewsInterface } from 'types';
import { Placeholder } from 'components';
import { getNews, putReadNews } from 'services';
import ItemNews from './ItemNews';

import './style.scss';

export default function NewsPage() {
  const { user } = useAuth();
  const { newsIDs, loadingNewsIDs } = useBasic();

  const [loading, setLoading] = useState<boolean>(true);
  const [news, setNews] = useState<NewsInterface[] | undefined | null>();

  const unreadNews: number = useMemo((): number => {
    if (newsIDs && newsIDs?.length) {
      const ids = newsIDs.filter(id => !id.read);

      return ids.length;
    } else {
      return 0;
    }
  }, [newsIDs]);

  const fetchNews = useCallback(async () => {
    try {
      const _news = await getNews(user?._id);

      setNews(_news);
      setLoading(false);

      return _news;
    } catch (error) {
      setNews(null);
      setLoading(false);

      return null;
    }
  }, [user?._id]);

  const markRead = useCallback(
    async (ID: string) => {
      try {
        if (!ID?.length) return null;

        await putReadNews(user?._id, ID);

        document.dispatchEvent(new CustomEvent('reload:newsids'));
      } catch (error) {}
    },
    [user?._id],
  );

  useEffect(() => {
    if (user) {
      fetchNews();
    }
  }, [fetchNews, user]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const empty = (message: string) => {
    return (
      <>
        <h3 className="text-base py-28 text-center block">{message}</h3>
      </>
    );
  };

  if (loading || typeof news === 'undefined' || typeof newsIDs === 'undefined') {
    return (
      <div id="terms">
        <div className="grid gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
        </div>
      </div>
    );
  }

  return (
    <section id="news">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Notícias {unreadNews > 0 && <span>({unreadNews})</span>}</h2>

      <div className="news-container">
        {news?.length
          ? news.map(item => <ItemNews key={item._id} item={item} markRead={markRead} />)
          : empty('Não existem notícias neste momento.')}
      </div>
    </section>
  );
}
