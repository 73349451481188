import { api } from './api';

export async function getBadges() {
  const { data } = await api.get(`challenge`, {
    params: { orderby: 'extra.order', reverse: true, q: "'extra.hidden':null" },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter(item => item.techniques.includes('GT02'));
  }
}
