import { useState, useRef, useContext, useEffect } from 'react';
import { BiUser, BiRefresh, BiLogOut } from 'react-icons/bi';
import { ImExit } from 'react-icons/im';

import { uploadPicture, updateUserProfile, fetchDataURL, resizeImage } from 'services/profile.service';
import AvatarEditor from 'components/AvatarEditor';
import { useAuth } from 'contexts';

export default function UserInfo() {
  const { user, refreshUser, signOut } = useAuth();

  const [avatarLoading, setAvatarLoading] = useState(false);
  const avatarEditor = useRef<any>();

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);

  const onOpenAvatarEditor = () => {
    if (avatarLoading) return;
    avatarEditor.current?.open();
  };

  async function updateAvatarImage(dataURI: string) {
    try {
      const { uploads } = await uploadPicture(dataURI);
      const [{ url }] = uploads;
      if (url) {
        const url = uploads[0].url;
        const payload = {
          _id: user?._id,
          image: {
            small: { url },
            medium: { url },
            original: { url },
          },
        };

        const updatedUser = await updateUserProfile(payload);
        refreshUser();

        // console.log(updatedUser);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function prepareToUpdateAvatar(url: string) {
    if (!user) return;

    setAvatarLoading(true);

    const imgResult = await fetchDataURL(url);
    if (imgResult) {
      const imgResized = await resizeImage(imgResult);
      await updateAvatarImage(imgResized);
    }
  }

  return (
    <>
      <div id="user-info" className="hidden lg:block relative">
        <div className="lg:flex items-center justify-between space-x-3">
          <div className="hidden lg:block avatar w-16 h-16 bg-gray-100 rounded-full">
            <button onClick={onOpenAvatarEditor} className="w-full h-full flex items-center justify-center">
              {!avatarLoading && !!user?.image?.medium?.url && (
                <img id="avatar" src={user?.image.medium.url} alt="" className="w-full h-full rounded-full overflow-hidden" />
              )}
              {!avatarLoading && !user?.image?.medium?.url && <BiUser size={32} />}
              {avatarLoading && (
                <span>
                  <BiRefresh size={32} className="animate-spin" />
                </span>
              )}
            </button>
          </div>
          <div className="user-gamification lg:flex-1">
            <h2 className="username text-center mb-5 block lg:hidden text-xl font-bold leading-none">{user?.name}</h2>

            <div className="inline-flex items-center justify-center lg:justify-start text-center px-3 py-2 rounded-xl border border-white border-solid lg:border-0 border-opacity-20 lg:border-opacity-0 lg:bg-gray-100">
              <h2 className="hidden lg:block text-xl font-bold leading-none">{user?.name}</h2>

              <span className="mx-4 hidden lg:inline text-lg text-white">|</span>

              <p>
                EXP
                <strong className="block -mt-1">{user?.point_categories.xp || '0'}</strong>
              </p>

              {!!user?.level?.level && (
                <>
                  <span className="mx-4 text-lg text-white text-opacity-20 lg:text-opacity-100">|</span>

                  <p>
                    <strong className="block">{user.level.level}</strong>
                  </p>
                </>
              )}

              <span className="mx-4 text-lg text-white text-opacity-20 lg:text-opacity-100">|</span>

              <p>
                DPS Coins
                <strong className="block -mt-1">{user?.point_categories.coins || '0'}</strong>
              </p>
            </div>
          </div>
          <div className="logout hidden lg:flex space-x-3">
            {/* <button type="button" className="text-indigo-400">
      <FaCog size={44} />
    </button> */}
            <button onClick={() => signOut()} type="button" className="text-red-600 opacity-50" title="Sair">
              {/* <BiLogOut size={44} /> */}
              <ImExit size={35} />
            </button>
          </div>
        </div>
      </div>
      <AvatarEditor
        ref={avatarEditor}
        onRenderStart={() => {
          setAvatarLoading(true);
        }}
        onRenderFinished={url => {
          prepareToUpdateAvatar(url);
        }}
      />
    </>
  );
}
