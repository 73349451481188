import { createContext, useContext } from 'react';

import { useAuth, useToast } from 'contexts';
import { ChildrenProp } from 'types';
import { useEvent } from 'utils';

interface PrivateContextInterface {
  // connect: () => void;
  // disconnect: () => void;
  // sendMessage: (message:any) => void;
}

const PrivateContext = createContext({} as PrivateContextInterface);

function PrivateProviderData(): PrivateContextInterface {
  const { refreshUser } = useAuth();
  const { toastify } = useToast();

  useEvent('websocket-private-message', handleMessage);

  async function handleMessage({ detail }: CustomEvent) {
    await refreshUser();

    try {
      if (!detail?.extra?.hideRealTimeNotification) {
        if (detail?.extra?.typeRealTimeNotification === 'toast') {
          if (detail?.type === 'challenge') {
            toastify({
              content: `🚀 Você completou um desafio${detail?.title ? `: ${detail?.title}` : '.'}`,
              type: 'success',
            });
          } else if (detail?.type === 'point') {
            toastify({
              content: `🚀 Você ganhou ${Math.round(detail?.total || 0)} ${detail.title}.`,
              type: 'success',
            });
          }
        } else {
          window.postMessage({ type: 'achievement', value: detail }, '*');
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return {};
}

const PrivateProvider = ({ children }: ChildrenProp) => {
  const contextData: PrivateContextInterface = PrivateProviderData();
  return <PrivateContext.Provider value={contextData}>{children}</PrivateContext.Provider>;
};

const usePrivate = (): PrivateContextInterface => {
  const context = useContext(PrivateContext);

  if (typeof context === 'undefined') {
    throw new Error('usePrivate must be used within an PrivateProvider');
  }

  return context;
};

export { PrivateProvider, usePrivate };
