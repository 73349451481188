import { useEffect, useState } from 'react';

import { Placeholder } from 'components';
import { TermsInterface } from 'types';
import { getTerms } from 'services';

import './style.scss';

export default function TermsPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TermsInterface>();

  useEffect(() => {
    getTerms()
      .then(_data => setData(_data))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div id="terms">
        <div className="grid gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
        </div>
      </div>
    );
  }

  return (
    <section id="terms">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Termos de uso</h2>

      <div className="content" dangerouslySetInnerHTML={{ __html: data?.content || '' }} />
    </section>
  );
}
