import { useEffect, useState } from 'react';
import { FaMedal } from 'react-icons/fa';
import { BsCoin } from 'react-icons/bs';

import { Placeholder } from 'components';
import { useAuth, useBasic } from 'contexts';
import { getBadges } from 'services';

import './style.scss';

type BadgesProps = {
  className?: string;
  [key: string]: any;
};

export function Badges({ className, ...rest }: BadgesProps) {
  const { user } = useAuth();
  const { pointsCategories } = useBasic();

  const [loading, setLoading] = useState(true);
  const [challenges, setChallenges] = useState<any[]>();

  function isQuestCompleted(challengeId: string) {
    if (!user) return 0;
    return user.challenges[challengeId] || 0;
  }

  function getQuestProgress(challengeId: string) {
    if (!user) return false;
    try {
      const challengeProgress = user.challenge_progress?.find(item => item.challenge === challengeId).percent_completed;
      return challengeProgress;
    } catch (e) {
      return 0;
    }
  }

  const getPointObj = (point: any) => {
    const pointObj = pointsCategories?.find(item => item._id === point.category);

    return (
      <div
        key={point.category}
        data-tip={pointObj?.category}
        className="border rounded-xl px-1 flex flex-row items-center space-x-1"
      >
        <strong>{point.operation === 0 ? point.total : point.operation === 1 ? 'Multiple' : ''}</strong>{' '}
        {!!pointObj && pointObj.image ? (
          <>
            <img src={pointObj.image} alt={pointObj.category} style={{ width: 14 }} />
          </>
        ) : (
          <>
            {!!point?.category?.match(/xp/gi) ? <FaMedal size={14} className="block" /> : <BsCoin size={14} className="block" />}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    async function loadData() {
      const data = await getBadges();
      const preparedData = data.filter(item => {
        if (item.extra) {
          if (item.extra.type) {
            return item.extra.type === user?.extra.type;
          }
          if (item.extra.country) {
            return item.extra.country === user?.extra.country;
          }
          if (item.extra.position) {
            return item.extra.country === user?.extra.position;
          }
          if (item.extra.team) {
            return item.extra.team === user?.extra.team;
          }
          if (item.extra.contract) {
            return item.extra.team === user?.extra.contract;
          }
        }
        return true;
      });
      setChallenges(preparedData);
      setLoading(false);
    }

    loadData();
  }, [user]);

  function loader() {
    return (
      <div className="grid h-48 gap-4">
        <Placeholder className="w-full" />
        <Placeholder className="w-3/4" />
        <Placeholder className="w-full" />
        <Placeholder className="w-3/4" />
      </div>
    );
  }

  function renderList() {
    return challenges?.map(item => {
      const completed = isQuestCompleted(item._id);
      const progress = getQuestProgress(item._id);

      return (
        <div
          key={item._id}
          className="group relative flex flex-col 2xl:flex-row text-center 2xl:text-left justify-center items-center p-2 bg-white rounded-xl w-full space-x-1 border"
        >
          {item.badge || completed > 0 ? (
            <div className="flex-none ml-1 2xl:mr-2 text-center">
              {item.badge && (
                <img src={item.badge.medium.url} alt="Challenge icon" className="w-16 h-16 object-contain rounded-full mx-auto" />
              )}

              {completed > 0 && (
                <div className="mt-1 text-xs font-bold text-green-500">
                  Completou
                  {completed > 1 && (
                    <>
                      <br />
                      <span> {completed}x</span>
                    </>
                  )}
                  !
                </div>
              )}
            </div>
          ) : null}
          <div className="flex-1 leading-none text-sm">
            <div className="text-blue-500 font-bold">{item.challenge}</div>

            <div className="flex flex-row items-center w-full">
              {progress > 0 && (
                <div className="w-full bg-red-500">
                  <div className="p-1 bg-yellow-600" style={{ width: `${progress}%` }}></div>
                </div>
              )}
              {progress > 0 && <span className="text-xs font-bold ml-2 text-yellow-600">{Math.round(progress)}%</span>}
            </div>

            {!!item.description && <div className="mt-1 text-xs">{item.description}</div>}

            {!!item.points && (
              <div className="my-1 flex flex-row items-center justify-center 2xl:justify-start space-x-1 flex-1 text-xs text-purple">
                <strong>Pontos:</strong>
                {item.points
                  .sort((a: any, b: any) => b.category.localeCompare(a.category))
                  .map((point: any) => getPointObj(point))}
              </div>
            )}

            {/* {item.extra && item.extra.self_report && (
                    <div className="mt-1">
                      <button onClick={() => handleCheckIn(item)}>Check-in</button>
                    </div>
                  )} */}
          </div>
        </div>
      );
    });
  }

  if (loading) {
    return <div className={`badges ${className || ''}`}>{loader()}</div>;
  }

  if (!challenges || !challenges.length) {
    return <></>;
  }

  return (
    <div className={`badges ${className || ''}`}>
      <h3 className="title text-lg mb-2 w-full block font-medium">Badges</h3>
      {renderList()}
    </div>
  );
}
